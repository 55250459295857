import { Box, HStack, VStack } from '@chakra-ui/react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import { css } from '@emotion/react';
import { AppLogo, RazorLabsLogo } from 'src/components/Logo';
import { VideoBackground } from 'src/layouts/components/VideoBackground';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';

export function AuthLayout() {
  const location = useLocation();
  const { user } = useAuth();

  if (user) {
    return <Navigate to={locations.protected.sites.index} state={{ from: location }} replace />;
  }

  return (
    <HStack css={style} spacing={0}>
      <Box w="50%" h="full">
        <Box position="absolute" top="60px" left="70px">
          <AppLogo size="164px" />
        </Box>
        <Outlet />
      </Box>

      <Box w="50%" h="full">
        <VideoBackground />
        <VStack h="100%" justifyContent="center">
          <RazorLabsLogo />
        </VStack>
      </Box>
    </HStack>
  );
}

const style = css`
  width: 100%;
  height: 100vh;
`;
