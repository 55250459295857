import { faker } from '@faker-js/faker';
import { HEALTH_STATUS } from 'src/const';

type FeedbackStatus = 'no_feedback' | 'resolved' | 'unresolved';

type DiagnosticCause = {
  cause_description: string;
  feedback: FeedbackStatus;
};

type DiagnosticAction = {
  action_description: string;
  feedback: FeedbackStatus;
};

type SourceInfo = {
  source_type: string;
  health_status: string;
  evidence_image_id: string;
  evidence_text: string;
  sensors_health_status: Array<SensorHealthStatus>;
};

type SensorHealthStatus = {
  last_uptime: string;
  sensor_name: string;
  sensor_type: string;
  health_status: string;
  located_at: string;
};

export type ComponentDiagnostic = {
  component_id: string;
  decision_datetime: string;
  recorded_datetime: string;
  fault_type: string;
  health_status: string;
  potential_downtime: number;
  possible_causes: Array<DiagnosticCause>;
  prescriptive_actions: Array<DiagnosticAction>;
  sources_info: Array<SourceInfo>;
};

export type AssetsDiagnostics = {
  asset_id: string;
  potential_downtime: number;
  energy_saving: number;
  components_diagnostics: Array<ComponentDiagnostic>;
};

export type Diagnostics = {
  site_id: string;
  assets_diagnostics: Array<AssetsDiagnostics>;
};

export const diagnostics: Diagnostics = {
  site_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
  assets_diagnostics: faker.helpers.uniqueArray(
    () => ({
      asset_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
      potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
      energy_saving: faker.datatype.number({ min: 10, max: 99 }),
      components_diagnostics: [
        ...faker.helpers.uniqueArray(
          () => ({
            component_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
            decision_datetime: faker.date.recent().toISOString(),
            recorded_datetime: faker.date.recent().toISOString(),
            fault_type: HEALTH_STATUS.CRITICAL,
            health_status: HEALTH_STATUS.CRITICAL,
            potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
            possible_causes: [],
            prescriptive_actions: [],
            sources_info: faker.helpers.uniqueArray(
              () => ({
                source_type: 'source_type',
                health_status: 'critical',
                evidence_image_id: 'image',
                evidence_text: 'evidence_text',
                sensors_health_status: [
                  {
                    last_uptime: 'string',
                    sensor_name: 'string',
                    sensor_type: 'string',
                    health_status: 'string',
                    located_at: 'string',
                  },
                ],
              }),
              10
            ),
          }),
          1
        ),
        ...faker.helpers.uniqueArray(
          () => ({
            component_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
            decision_datetime: faker.date.recent().toISOString(),
            recorded_datetime: faker.date.recent().toISOString(),
            fault_type: HEALTH_STATUS.ALARM,
            health_status: HEALTH_STATUS.ALARM,
            potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
            possible_causes: [],
            prescriptive_actions: [],
            sources_info: faker.helpers.uniqueArray(
              () => ({
                source_type: 'source_type',
                health_status: 'critical',
                evidence_image_id: 'image',
                evidence_text: 'evidence_text',
                sensors_health_status: [
                  {
                    last_uptime: 'string',
                    sensor_name: 'string',
                    sensor_type: 'string',
                    health_status: 'string',
                    located_at: 'string',
                  },
                ],
              }),
              10
            ),
          }),
          1
        ),
        ...faker.helpers.uniqueArray(
          () => ({
            component_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
            decision_datetime: faker.date.recent().toISOString(),
            recorded_datetime: faker.date.recent().toISOString(),
            fault_type: HEALTH_STATUS.MONITOR,
            health_status: HEALTH_STATUS.MONITOR,
            potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
            possible_causes: [],
            prescriptive_actions: [],
            sources_info: faker.helpers.uniqueArray(
              () => ({
                source_type: 'source_type',
                health_status: 'critical',
                evidence_image_id: 'image',
                evidence_text: 'evidence_text',
                sensors_health_status: [
                  {
                    last_uptime: 'string',
                    sensor_name: 'string',
                    sensor_type: 'string',
                    health_status: 'string',
                    located_at: 'string',
                  },
                ],
              }),
              10
            ),
          }),
          1
        ),
        ...faker.helpers.uniqueArray(
          () => ({
            component_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
            decision_datetime: faker.date.recent().toISOString(),
            recorded_datetime: faker.date.recent().toISOString(),
            fault_type: HEALTH_STATUS.HEALTHY,
            health_status: HEALTH_STATUS.HEALTHY,
            potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
            possible_causes: [],
            prescriptive_actions: [],
            sources_info: faker.helpers.uniqueArray(
              () => ({
                source_type: 'source_type',
                health_status: 'healthy',
                evidence_image_id: 'image',
                evidence_text: 'evidence_text',
                sensors_health_status: [
                  {
                    last_uptime: 'string',
                    sensor_name: 'string',
                    sensor_type: 'string',
                    health_status: 'string',
                    located_at: 'string',
                  },
                ],
              }),
              10
            ),
          }),
          1
        ),
      ],
    }),
    6
  ),
};
