import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Box } from '@chakra-ui/react';
import { SummaryDataType } from 'src/data/summary';
import { SummaryChartTotal } from 'src/pages/sites/components/summary/components/SummaryChartTotal';

type SummaryChartProps = {
  data: SummaryDataType[];
};

export const SummaryChart = ({ data }: SummaryChartProps) => {
  const total = data.reduce((total: number, item: SummaryDataType) => total + item.value, 0);

  return (
    <Box position="relative" h="12rem" mb={12}>
      <SummaryChartTotal total={total} />
      <ResponsivePie
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        startAngle={-100}
        innerRadius={0.9}
        padAngle={2}
        cornerRadius={45}
        activeOuterRadiusOffset={8}
        colors={{ datum: 'data.color' }}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
      />
    </Box>
  );
};
