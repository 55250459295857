import { useEffect, useState } from 'react';

export const useSticky = () => {
  const [sticky, setSticky] = useState(false);

  const isSticky = () => {
    const sticky = window.scrollY >= 56;
    setSticky(sticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', isSticky);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  return [sticky];
};
