import React, { ReactElement } from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';

interface FormLayoutProps {
  title: string;
  subtitle: string;
  icon?: ReactElement<any, any>;
  children?: any;
}

export const FormLayout = ({ title, subtitle, icon, children }: FormLayoutProps) => {
  return (
    <VStack h="full" justifyContent="center">
      <Box maxW="26.25rem">
        {icon}

        <Box>
          <Heading fontSize="1.5rem" fontWeight={600}>
            <FormattedText label={title} />
          </Heading>
        </Box>

        <Box pt={6} pb={10}>
          <FormattedText label={subtitle} />
        </Box>

        {children}
      </Box>
    </VStack>
  );
};
