import React, { useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import { Asset } from 'src/app/types';
import { MachinesGroup } from 'src/pages/machines/components/MachinesGroup';

type MachinesListProps = {
  assets?: Array<Asset>;
};

export const MachinesList = ({ assets }: MachinesListProps) => {
  const assetsUnhealthy = useMemo(
    () =>
      assets
        ? assets.filter(({ health_status }) => ![HEALTH_STATUS.HEALTHY, HEALTH_STATUS.MONITOR].includes(health_status))
        : [],
    [assets]
  );

  const assetsHealthy = useMemo(
    () =>
      assets
        ? assets.filter(({ health_status }: Asset) =>
            [HEALTH_STATUS.HEALTHY, HEALTH_STATUS.MONITOR].includes(health_status)
          )
        : [],
    [assets]
  );

  return (
    <VStack spacing={12} position="relative">
      {assetsUnhealthy.length ? (
        <MachinesGroup title="page.machines.groups.unhealthy" assets={assetsUnhealthy} isRequireAttention />
      ) : null}

      {assetsHealthy.length ? <MachinesGroup title="page.machines.groups.healthy" assets={assetsHealthy} /> : null}
    </VStack>
  );
};
