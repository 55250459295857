import React from 'react';
import { Box, Image, VStack } from '@chakra-ui/react';
import agreementImage from 'src/assets/images/agreement-image.png';
export const Agreement = () => {
  return (
    <VStack spacing={4} alignItems="flex-start">
      <Box pl={2} pt={2}>
        Razor Labs Estimated downtime based on industry experience
      </Box>
      <Box>
        <Image src={agreementImage} />
      </Box>
    </VStack>
  );
};
