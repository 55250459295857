import React from 'react';
import { IconButton, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { SIDEBAR_MENU_BUTTON_ACTIVE_STYLE, SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMLogoutIcon, DMPredictIcon, DMSettingsIcon, DMSitesIcon } from 'src/components/Icons';
import { LegendPopover } from 'src/components/legend/LegendPopover';
import { BusinessReview } from 'src/components/BusinessReview';
import { AppIcon } from 'src/components/Logo';
import Notifications from 'src/components/Notifications';
import { useNavigate } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';

export const NavigationMenu = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const onLogoutClick = async () => {
    try {
      await signOut();
      navigate(locations.public.login);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <VStack justifyContent="space-between" h="full" spacing={0}>
      <VStack spacing={0}>
        <NavLink to="/">
          <AppIcon />
        </NavLink>

        <NavLink to="sites">
          {({ isActive }) => (
            <IconButton
              aria-label="Sites"
              size="lg"
              css={isActive ? SIDEBAR_MENU_BUTTON_ACTIVE_STYLE : undefined}
              icon={<DMSitesIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
              variant="unstyled"
            />
          )}
        </NavLink>

        <IconButton
          aria-label="Predict"
          size="lg"
          isDisabled
          icon={<DMPredictIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
          variant="unstyled"
        />
      </VStack>

      <VStack spacing={0}>
        <BusinessReview />
        <LegendPopover />
        <Notifications />

        <NavLink to="settings">
          {({ isActive }) => (
            <IconButton
              aria-label="Settings"
              size="lg"
              css={isActive ? SIDEBAR_MENU_BUTTON_ACTIVE_STYLE : undefined}
              icon={<DMSettingsIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
              variant="unstyled"
            />
          )}
        </NavLink>

        <IconButton
          onClick={onLogoutClick}
          aria-label="Logout"
          size="lg"
          icon={<DMLogoutIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
          variant="unstyled"
        />
      </VStack>
    </VStack>
  );
};
