import React from 'react';
import { Box, HStack, Tag, TagLabel, TagLeftIcon, VStack } from '@chakra-ui/react';
import {
  DMMachineIcon,
  DMStatusAlarmIcon,
  DMStatusCriticalIcon,
  DMStatusHealthyIcon,
  DMStatusMonitorIcon,
  DMStatusNotAvailableIcon,
} from 'src/components/Icons';
import { Site } from 'src/app/services/sites';
import { generatePath, useNavigate } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { format } from 'date-fns';
import { REGULAR_DATE_FORMAT } from 'src/const';

type SitesRowProps = {
  item: Site;
};

export const SitesRow = ({ item }: SitesRowProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(generatePath(locations.protected.sites.machines, { siteId: item.site_id }));
  };

  return (
    <HStack
      boxShadow="sm"
      bgColor="white"
      p="1.5rem"
      pb="0.8rem"
      borderRadius="md"
      w="full"
      alignItems="flex-start"
      justifyContent="space-between"
      onClick={onClick}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
      }}
    >
      <Box>
        <Box fontWeight="700">{item.site_name}</Box>
        <Box fontSize="0.75rem">Last recorded: {format(new Date(item.last_recorded), REGULAR_DATE_FORMAT)}</Box>
      </Box>
      <HStack spacing={8} alignItems="flex-start" textAlign="center">
        <Box w="4rem">
          <Tag size="lg" variant="unstyled" color="black">
            <TagLeftIcon boxSize="16px" as={DMMachineIcon} />
            <TagLabel fontSize="0.875rem" fontWeight={600}>
              12
            </TagLabel>
          </Tag>
        </Box>

        <VStack spacing="0.3rem" w="4rem">
          <Tag size="lg" bgColor="#de6f62" color="white">
            <TagLeftIcon boxSize="16px" as={DMStatusCriticalIcon} />
            <TagLabel fontSize="0.75rem" fontWeight={600}>
              {item.assets_health_state.critical.count}
            </TagLabel>
          </Tag>
          <Box fontSize="0.625rem" fontWeight={600} color="#7071f3">
            {item.assets_health_state.critical.potential_downtime} hr
          </Box>
        </VStack>

        <VStack spacing="0.3rem" w="4rem">
          <Tag size="lg" variant="outline" color="#ff891b" boxShadow="xs">
            <TagLeftIcon boxSize="16px" as={DMStatusAlarmIcon} />
            <TagLabel fontSize="0.75rem" fontWeight={600}>
              {item.assets_health_state.alarm.count}
            </TagLabel>
          </Tag>
          <Box fontSize="0.625rem" fontWeight={600} color="#7071f3">
            {item.assets_health_state.alarm.potential_downtime} hr
          </Box>
        </VStack>

        <VStack spacing="0.3rem" w="4rem">
          <Tag size="lg" variant="outline" color="#f1bf42" boxShadow="xs">
            <TagLeftIcon boxSize="16px" as={DMStatusMonitorIcon} />
            <TagLabel fontSize="0.75rem" fontWeight={600}>
              {item.assets_health_state.monitor.count}
            </TagLabel>
          </Tag>
          <Box fontSize="0.625rem" fontWeight={600} color="#7071f3">
            {item.assets_health_state.monitor.potential_downtime} hr
          </Box>
        </VStack>

        <VStack spacing="0.3rem" w="4rem">
          <Tag size="lg" variant="outline" color="#58a65c" boxShadow="xs">
            <TagLeftIcon boxSize="16px" as={DMStatusHealthyIcon} />
            <TagLabel fontSize="0.75rem" fontWeight={600}>
              {item.assets_health_state.healthy.count}
            </TagLabel>
          </Tag>
          <Box fontSize="0.625rem" fontWeight={600} color="#7071f3">
            {item.assets_health_state.healthy.potential_downtime} hr
          </Box>
        </VStack>

        <VStack spacing="0.3rem" w="4rem">
          <Tag size="lg" bgColor="##f7f7f7" color="black">
            <TagLeftIcon boxSize="16px" as={DMStatusNotAvailableIcon} />
            <TagLabel fontSize="0.75rem" fontWeight={600}>
              {item.assets_health_state.na.count}
            </TagLabel>
          </Tag>
          <Box fontSize="0.625rem" fontWeight={600} color="#7071f3">
            {item.assets_health_state.na.potential_downtime} hr
          </Box>
        </VStack>
      </HStack>
    </HStack>
  );
};
