import React from 'react';
import { AlertRow } from 'src/components/AlertRow';
import { Alert } from 'src/data/alerts';

export const AlertsList = ({ alerts }: { alerts: Alert[] }) => {
  return (
    <>
      {alerts.map((item, index) => (
        <AlertRow alert={item} key={index} />
      ))}
    </>
  );
};
