import { Box, Circle, Divider, HStack, Icon } from '@chakra-ui/react';
import React from 'react';
import { Alert } from 'src/data/alerts';
import { HEALTH_STATES } from 'src/const';

export const AlertRow = ({ alert }: { alert: Alert }) => {
  const state = HEALTH_STATES[alert.health_status];

  return (
    <>
      <HStack spacing={3}>
        <Box>
          <Circle size="2.5rem" bgColor="gray.100">
            <Icon as={state.icon} fontSize="1rem" color={`health.${alert.health_status}`} />
          </Circle>
        </Box>
        <Box fontSize="0.75rem" py="1.25rem">
          <Box fontWeight={600}>{alert.asset_name}</Box>
          <Box>Sunrise Dam Gold Mine {alert.recorded_datetime}</Box>
        </Box>
      </HStack>
      <Divider />
    </>
  );
};
