import { Box, BoxProps, HStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import React from 'react';
import { css } from '@emotion/react';

interface FilterButtonProps extends BoxProps {
  amount: number;
  label: string;
  status?: HEALTH_STATUS;
  active?: boolean;
  onClick?: () => void;
}

export const FilterButton = ({ amount, label, status, active, ...rest }: FilterButtonProps) => {
  return (
    <HStack
      borderRight="1px solid rgba(1, 10, 9, 0.1)"
      h="3rem"
      px={4}
      cursor="pointer"
      css={{
        ...(active ? activeFilterButton : null),
      }}
      {...rest}
    >
      <Box fontSize="1.25rem" fontWeight={600} color={`health.${status}`}>
        {amount}
      </Box>
      <Box fontSize="0.625rem" textTransform="uppercase" fontWeight={500} letterSpacing={1.1}>
        {label}
      </Box>
    </HStack>
  );
};

const activeFilterButton = css`
  background-color: rgba(112, 113, 243, 0.12);
`;
