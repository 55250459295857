import React from 'react';
import { Box, HStack, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { DMArrowRightIcon, DMMachineIcon } from 'src/components/Icons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { StackProps } from '@chakra-ui/layout/dist/stack/stack';

interface MachinesDropdownProps extends StackProps {
  current?: string;
}

export const MachinesDropdown = ({ current, ...rest }: MachinesDropdownProps) => {
  return (
    <HStack {...rest}>
      <Box>
        <DMArrowRightIcon fontSize="1.5rem" />
      </Box>

      <Menu>
        <MenuButton>
          <HStack>
            <DMMachineIcon fontSize="1.5rem" />
            <Box fontSize="1rem" fontWeight={500}>
              {current}
            </Box>
            <ChevronDownIcon fontSize="1.5rem" />
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem>
            <Box>
              <Box fontSize="0.625rem">CRC04</Box>
              <Box fontSize="0.875rem" fontWeight={500}>
                Tertiary Crusher 2
              </Box>
            </Box>
          </MenuItem>
          <MenuItem>
            <Box>
              <Box fontSize="0.625rem">CRC04</Box>
              <Box fontSize="0.875rem" fontWeight={500}>
                Tertiary Crusher 3
              </Box>
            </Box>
          </MenuItem>
          <MenuItem>
            <Box>
              <Box fontSize="0.625rem">CRC04</Box>
              <Box fontSize="0.875rem" fontWeight={500}>
                Tertiary Crusher 4
              </Box>
            </Box>
          </MenuItem>
          <MenuItem>
            <Box>
              <Box fontSize="0.625rem">CRC04</Box>
              <Box fontSize="0.875rem" fontWeight={500}>
                Tertiary Crusher 5
              </Box>
            </Box>
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};
