import { Avatar, Box, HStack } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import userAvatar from 'src/assets/images/user-avatar.png';

export const UserMenu = () => {
  return (
    <NavLink to="settings">
      <HStack>
        <Box fontSize="0.75rem" fontWeight={600}>
          John Doe
        </Box>
        <Avatar size="sm" name="John Doe" src={userAvatar} />
      </HStack>
    </NavLink>
  );
};
