import { faker } from '@faker-js/faker';
import { HEALTH_STATUS } from 'src/const';

export type Alert = {
  alert_id: string;
  site_id: string;
  site_name: string;
  asset_id: string;
  asset_name: string;
  health_status: string;
  recorded_datetime: string;
  decision_datetime: string;
};

const array = [HEALTH_STATUS.CRITICAL, HEALTH_STATUS.ALARM, HEALTH_STATUS.MONITOR];

export const alerts: Alert[] = faker.helpers.uniqueArray(
  () => ({
    alert_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    site_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    site_name: `${faker.commerce.productName()} Mine`,
    asset_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    asset_name: `Tertiary Crusher 2 Alert`,
    health_status: array[Math.floor(Math.random() * array.length)],
    recorded_datetime: new Date().toISOString(),
    decision_datetime: new Date().toISOString(),
  }),
  10
);
