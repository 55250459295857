import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@chakra-ui/react';
import { SummaryChart } from 'src/pages/sites/components/summary/components/SummaryChart';
import { summary } from 'src/data/summary';
import { SummaryHeader } from 'src/pages/sites/components/summary/components/SummaryHeader';
import { SummaryLegend } from 'src/pages/sites/components/summary/components/SummaryLegend';

export const Summary = () => {
  return (
    <Box css={style}>
      <SummaryHeader />
      <SummaryChart data={summary} />
      <SummaryLegend data={summary} />
    </Box>
  );
};

const style = css`
  width: 21.25rem;
  padding: 7.5rem 4rem 4rem 4rem;
  background-color: white;
  border-left: 1px solid rgba(1, 10, 9, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 3;
`;
