import React from 'react';
import { Box, Button, Divider, HStack, Table, Tbody, Td, Tr, VStack } from '@chakra-ui/react';
import {
  DMPlusCircleIcon,
  DMSensorsIcon,
  DMStatusAlarmIcon,
  DMStatusCriticalIcon,
  DMStatusHealthyIcon,
  DMStatusNotAvailableIcon,
} from 'src/components/Icons';
import { ComponentPreviewModal } from 'src/pages/machines/modals/ComponentPreviewModal';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { FUSION_TRENDS_TYPE, HEALTH_STATUS } from 'src/const';
import { FusionEvidenceModal } from 'src/pages/machines/modals/FusionEvidenceModal';
import vibrationImage from 'src/assets/images/vibration-image.png';
import { css } from '@emotion/react';
import { ComponentDiagnostic } from 'src/data/diagnostics';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import { FeedbackThumbs } from 'src/pages/machines/components/FeedbackThumbs';
import { FusionTrendsModal } from 'src/pages/machines/modals/FusionTrendsModal';
import { StackProps } from '@chakra-ui/layout/dist/stack/stack';
import { ButtonNaked } from 'src/components/ButtonNaked';
import { SensorHealthStatus } from 'src/data/sensors';

interface MachineFailureRowProps extends StackProps {
  item: ComponentDiagnostic;
  sensors: SensorHealthStatus[];
  isHiddenButtons?: boolean;
  noShadow?: boolean;
}

export const MachineFailureRow = ({ item, sensors, isHiddenButtons, noShadow, ...rest }: MachineFailureRowProps) => {
  return (
    <HStack
      bgColor="white"
      borderRadius="xl"
      boxShadow={noShadow ? undefined : 'sm'}
      w="full"
      alignItems="flex-start"
      spacing={0}
      {...rest}
    >
      <Box w="full">
        <HStack minH="5rem" w="full" py={3} px={6} justifyContent="space-between">
          <HStack spacing={4}>
            <ComponentPreviewModal />
            <Box>
              <Box fontWeight={600}>Pump</Box>
              <Box fontSize="0.875rem">Looseness</Box>
            </Box>
          </HStack>

          {isHiddenButtons ? null : (
            <HStack>
              <ButtonNaked label="Create work order" leftIcon={<DMPlusCircleIcon fontSize="1.5rem" />} isDisabled />
              <FusionTrendsModal title="Component fusion trend" type={FUSION_TRENDS_TYPE.Component} />
            </HStack>
          )}
        </HStack>

        <Divider />

        <HStack h="100%" w="full" p={6} alignItems="flex-start" justifyContent="stretch" spacing={8}>
          <Box w="40%">
            <HStack justifyContent="space-between" pb={2}>
              <HStack fontWeight={600} spacing={2} alignItems="center">
                <DMStatusNotAvailableIcon color="gray.400" />
                <Box>Cause</Box>
              </HStack>
              <Box fontSize="0.875rem">Confirm?</Box>
            </HStack>

            <Table
              variant="striped"
              size="sm"
              css={{
                td: {
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                },
              }}
            >
              <Tbody>
                <Tr>
                  <Td>&#9675; Loose mounting bolts</Td>
                  <Td>
                    <FeedbackThumbs />
                  </Td>
                </Tr>
                <Tr>
                  <Td> &#9675; Structural looseness</Td>
                  <Td>
                    <FeedbackThumbs />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <Box w="60%">
            <HStack justifyContent="space-between" pb={2}>
              <HStack fontWeight={600} spacing={2} alignItems="center">
                <DMStatusHealthyIcon color="gray.400" />
                <Box>Action</Box>
              </HStack>
              <Box fontSize="0.875rem">Resolved?</Box>
            </HStack>

            <Table
              variant="striped"
              size="sm"
              css={{
                td: {
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                },
              }}
            >
              <Tbody>
                <Tr>
                  <Td>&#9675; Tighten all mounting bolts to prevent looseness frame distortion</Td>
                  <Td>
                    <FeedbackThumbs />
                  </Td>
                </Tr>
                <Tr>
                  <Td>&#9675; Inspect looseness or frame distortion </Td>
                  <Td>
                    <FeedbackThumbs />
                  </Td>
                </Tr>
                <Tr>
                  <Td>&#9675; It is suspected that a structural design flaw of the pump motor</Td>
                  <Td>
                    <FeedbackThumbs />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </HStack>
      </Box>

      <Box borderRightRadius="md">
        <VStack
          h="5rem"
          w="26rem"
          p={4}
          color="white"
          fontWeight="600"
          borderTopRightRadius="md"
          spacing={1}
          alignItems="flex-start"
          css={{
            ...(item.health_status === HEALTH_STATUS.CRITICAL ? criticalStyle : null),
            ...(item.health_status === HEALTH_STATUS.ALARM ? alarmStyle : null),
            ...(item.health_status === HEALTH_STATUS.MONITOR ? monitorStyle : null),
            ...(item.health_status === HEALTH_STATUS.HEALTHY ? healthyStyle : null),
          }}
        >
          <Box>Fusion & Evidence</Box>
          <HealthStatusIndicator type={item.health_status} color="white" />
        </VStack>

        <Box>
          <Table
            size="sm"
            css={{
              td: {
                paddingTop: '0.25rem',
                paddingBottom: '0.25rem',
              },
            }}
          >
            <Tbody>
              <Tr
                css={{
                  ...(item.health_status === HEALTH_STATUS.CRITICAL ? criticalEvidenceRowStyle : null),
                }}
              >
                <Td borderX="1px solid #EDF2F7">
                  <HStack justifyContent="space-between">
                    <HStack>
                      <DMStatusCriticalIcon mr={2} color="health.critical" />
                      <Box fontWeight={500}>Vibration</Box>
                    </HStack>

                    <FusionEvidenceModal header="Vibration" image={vibrationImage} />
                  </HStack>
                </Td>
                <Td>
                  <MachineSensorsModal sensors={sensors} />
                </Td>
              </Tr>

              <Tr>
                <Td borderX="1px solid #EDF2F7">
                  <HStack justifyContent="space-between">
                    <HStack>
                      <DMStatusAlarmIcon mr={2} color="health.alarm" />
                      <Box fontWeight={500}>Oil</Box>
                    </HStack>

                    <FusionEvidenceModal header="Oil" image={vibrationImage} />
                  </HStack>
                </Td>
                <Td>
                  <MachineSensorsModal sensors={sensors} />
                </Td>
              </Tr>

              <Tr>
                <Td borderX="1px solid #EDF2F7">
                  <HStack justifyContent="space-between">
                    <HStack>
                      <DMStatusHealthyIcon mr={2} color="health.healthy" />
                      <Box fontWeight={500}>CCTV</Box>
                    </HStack>

                    <FusionEvidenceModal header="CCTV" image={vibrationImage} />
                  </HStack>
                </Td>
                <Td>
                  <MachineSensorsModal sensors={sensors} />
                </Td>
              </Tr>

              <Tr>
                <Td borderX="1px solid #EDF2F7">
                  <HStack justifyContent="space-between">
                    <HStack>
                      <DMStatusHealthyIcon mr={2} color="transparent" />
                      <Box fontWeight={500}>OPS Data</Box>
                    </HStack>
                  </HStack>
                </Td>
                <Td>
                  <Button variant="unstyled" display="flex" justifyContent="flex-start" size="sm">
                    <DMSensorsIcon fontSize="1.5rem" mr={2} color="gray.300" />
                  </Button>
                </Td>
              </Tr>

              <Tr>
                <Td borderX="1px solid #EDF2F7">
                  <HStack justifyContent="space-between">
                    <HStack>
                      <DMStatusHealthyIcon mr={2} color="transparent" />
                      <Box fontWeight={500}>Thermo</Box>
                    </HStack>
                  </HStack>
                </Td>
                <Td>
                  <Button variant="unstyled" display="flex" justifyContent="flex-start" size="sm">
                    <DMSensorsIcon fontSize="1.5rem" mr={2} color="gray.300" />
                  </Button>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
    </HStack>
  );
};

const criticalEvidenceRowStyle = css`
  background-color: rgba(222, 111, 98, 0.12);
`;

const criticalStyle = css`
  background-color: #de6f62;
`;

const alarmStyle = css`
  background-color: #ff891b;
`;

const monitorStyle = css`
  background-color: #f1bf42;
`;

const healthyStyle = css`
  background-color: #58a65c;
`;
