import { Alert, AlertDescription, Box, HStack, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { Asset } from 'src/app/types';
import { HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import { css } from '@emotion/react';

type MachineItemProps = {
  item: Asset;
  isRequireAttention?: boolean;
};

export const MachineItem = ({ item, isRequireAttention }: MachineItemProps) => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const onMachineItemClick = () => {
    navigate(generatePath(locations.protected.sites.failures, { siteId, machineId: item.asset_id }));
  };

  return (
    <VStack
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      height="100%"
      p={4}
      spacing={2.5}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
      }}
      onClick={onMachineItemClick}
    >
      <HStack w="full" justifyContent="space-between">
        <Box>
          <Box fontSize="0.75rem" fontWeight={600}>
            {item.asset_id}
          </Box>
          <Box fontSize="1rem" fontWeight={700}>
            {item.asset_name}
          </Box>
        </Box>
        <Box>
          <MachineSensorsModal sensors={item.sensors_health_status} />
        </Box>
      </HStack>

      <HStack w="full" justifyContent="space-between" alignItems="flex-start">
        <HealthStatusIndicator type={item.health_status} />
        <Box fontSize="0.75rem">Last recorded: {format(new Date(item.last_recorded), REGULAR_DATE_FORMAT)}</Box>
      </HStack>

      {isRequireAttention ? (
        <VStack w="full" spacing={3}>
          <Alert
            status="error"
            borderRadius="xl"
            px={4}
            py={2}
            w="full"
            css={{
              ...(item.health_status === HEALTH_STATUS.CRITICAL ? criticalStyle : null),
              ...(item.health_status === HEALTH_STATUS.ALARM ? alarmStyle : null),
            }}
          >
            <AlertDescription fontSize="0.875rem">Structural mechanical looseness</AlertDescription>
          </Alert>

          <HStack fontSize="0.75rem" fontWeight={600} w="full">
            <Box>Downtime {item.potential_downtime} hr</Box>
            <Box color="#58a65c">Energy saving {item.energy_saving}</Box>
          </HStack>
        </VStack>
      ) : null}
    </VStack>
  );
};

const criticalStyle = css`
  background-color: rgba(255, 67, 55, 0.12);
  border: 1px solid #de6f62;
`;

const alarmStyle = css`
  background-color: rgba(255, 137, 27, 0.12);
  border: 1px solid #ff891b;
`;
