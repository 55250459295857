import { Box, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useGetAssetsQuery } from 'src/app/api';
import { MachinesList } from 'src/pages/machines/components/MachinesList';
import { Toolbar } from 'src/components/Toolbar';
import { FilterBar } from 'src/components/filter-bar/FilterBar';
import { INITIAL_FILTERS } from 'src/const';
import { Asset } from 'src/app/types';

export const MachinesPage = () => {
  const [selectedStatuses, setSelectedStatuses] = useState<Record<string, boolean>>(INITIAL_FILTERS);
  const [selectedSensors, setSelectedSensors] = useState<string>('all');

  const selectedStatusesKeys = Object.keys(selectedStatuses).filter((key) => selectedStatuses[key]);

  const { data: assets, isLoading } = useGetAssetsQuery(undefined, {
    selectFromResult: ({ data, error, isLoading }) => ({
      data: !selectedStatuses.all
        ? data?.filter((item: Asset) => item.health_status === selectedStatusesKeys[0])
        : data,
      error,
      isLoading,
    }),
  });

  const assetsFilteredBySensorsStatus =
    selectedSensors !== 'all' && assets
      ? assets.filter((item: Asset) => item.overall_sensors_status === selectedSensors)
      : assets;

  return (
    <>
      <Box w="full" p="4rem" pt="9rem">
        <Toolbar siteName="Sunrise Dam Gold Mine" isTopFixed />
        <FilterBar
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          selectedSensors={selectedSensors}
          setSelectedSensors={setSelectedSensors}
        />
        {isLoading ? <Spinner /> : <MachinesList assets={assetsFilteredBySensorsStatus} />}
      </Box>
    </>
  );
};
