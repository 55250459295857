export const locations = {
  base: '/',
  public: {
    login: '/login',
    resetPassword: '/reset-password',
    sent: '/reset-password/sent',
    change: '/reset-password/change',
    changed: '/reset-password/changed',
  },
  protected: {
    sites: {
      index: '/sites',
      machines: '/sites/:siteId/machines',
      failures: '/sites/:siteId/machines/:machineId/failures',
    },
    settings: {
      index: '/settings',
      account: '/settings/account',
      userManagement: '/settings/user-management',
      preferences: '/settings/preferences',
    },
  },
  fallback: '*',
};
