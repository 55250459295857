import { Table, Tr, Thead, Th, Tbody, Td, Tfoot, Box } from '@chakra-ui/react';
import { TableContainer } from '@chakra-ui/react';
import { css } from '@emotion/react';
import React from 'react';
import { OrganizationSummarySite, useGetOrganizationSummaryQuery } from 'src/app/services/summary';
import { LastRecorded } from 'src/components/LastRecorded';

export const DowntimeDataView = () => {
  const { data: organizationSummaryData } = useGetOrganizationSummaryQuery();

  return (
    <TableContainer overflowY="auto" maxHeight="50rem">
      <Table>
        <Thead css={tableHeadStyle} position="sticky" top={0}>
          <Tr>
            <Th>Site name</Th>
            <Th>Machines</Th>
            <Th>Current downtime</Th>
            <Th>Total downtime</Th>
            <Th>Current energy saving</Th>
            <Th>Total energy saving</Th>
          </Tr>
        </Thead>

        <Tbody css={tableBodyStyle}>
          {organizationSummaryData
            ? organizationSummaryData.map((item: OrganizationSummarySite, index) => (
                <Tr key={index}>
                  <Td>
                    <Box fontSize="0.875rem" fontWeight={600}>
                      {item.site_name}
                    </Box>
                    <LastRecorded date={new Date()} />
                  </Td>
                  <Td>{item.assets_count}</Td>
                  <Td>{item.current_potential_downtime} hr</Td>
                  <Td>{item.historical_potential_downtime} hr</Td>
                  <Td>{item.current_energy_saving} hr</Td>
                  <Td>{item.historical_energy_saving} hr</Td>
                </Tr>
              ))
            : null}
        </Tbody>
        <Tfoot css={tableFootStyle} position="sticky" bottom={0}>
          <Tr>
            <Th>20 Total sites</Th>
            <Th>240</Th>
            <Th>254:12 hr</Th>
            <Th>558:24 hr</Th>
            <Th>254:12 hr</Th>
            <Th>558:24 hr</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

const tableHeadStyle = css`
  border-radius: 1rem;
  background-color: rgba(225, 225, 225, 1);
  th {
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 0.625rem;
  },
`;

const tableBodyStyle = css`
  font-size: 0.875rem;
  td {
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
`;

const tableFootStyle = css`
  th {
    background-color: #c6fef7;
    font-size: 0.875rem;
    fontweight: 600;
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
`;
