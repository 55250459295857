import { faker } from '@faker-js/faker';
import { OrganizationSummarySite } from 'src/app/services/summary';

export const organizationSummary: Array<OrganizationSummarySite> = faker.helpers.uniqueArray(
  () => ({
    site_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    site_name: `${faker.commerce.productName()} Mine`,
    assets_count: faker.datatype.number({ min: 10, max: 30 }),
    current_potential_downtime: faker.datatype.number({ min: 1000, max: 2000 }),
    historical_potential_downtime: faker.datatype.number({ min: 1000, max: 2000 }),
    current_energy_saving: faker.datatype.number({ min: 1000, max: 2000 }),
    historical_energy_saving: faker.datatype.number({ min: 1000, max: 2000 }),
  }),
  24
);
