import { css } from '@emotion/react';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import {
  DMStatusAlarmIcon,
  DMStatusCriticalIcon,
  DMStatusHealthyIcon,
  DMStatusMonitorIcon,
  DMStatusNotAvailableIcon,
} from 'src/components/Icons';

export const SIDEBAR_MENU_BUTTON_SIZE = '24px';

export const SIDEBAR_MENU_BUTTON_ACTIVE_STYLE = css`
  color: #12ffe2;
`;

export enum HEALTH_STATUS {
  CRITICAL = 'critical',
  ALARM = 'alarm',
  MONITOR = 'monitor',
  HEALTHY = 'healthy',
  NOT_AVAILABLE = 'notAvailable',
}

export enum FUSION_TRENDS_TYPE {
  Machine = 'machine',
  Component = 'component',
}

export const REGULAR_DATE_FORMAT = 'dd.MM.yyyy';
export const DD_MM_DATE_FORMAT = 'dd.MM';
export const YYYY_FORMAT = 'yyyy';

type HealthState = {
  icon: ComponentWithAs<'svg', IconProps>;
  title: string;
  description: string;
};

export const HEALTH_STATES: Record<string, HealthState> = {
  [HEALTH_STATUS.CRITICAL]: {
    icon: DMStatusCriticalIcon,
    title: 'general.legend.list.critical.title',
    description: 'general.legend.list.critical.description',
  },
  [HEALTH_STATUS.ALARM]: {
    icon: DMStatusAlarmIcon,
    title: 'general.legend.list.alarm.title',
    description: 'general.legend.list.alarm.description',
  },
  [HEALTH_STATUS.MONITOR]: {
    icon: DMStatusMonitorIcon,
    title: 'general.legend.list.monitor.title',
    description: 'general.legend.list.monitor.description',
  },
  [HEALTH_STATUS.HEALTHY]: {
    icon: DMStatusHealthyIcon,
    title: 'general.legend.list.healthy.title',
    description: 'general.legend.list.healthy.description',
  },
  [HEALTH_STATUS.NOT_AVAILABLE]: {
    icon: DMStatusNotAvailableIcon,
    title: 'general.legend.list.notAvailable.title',
    description: 'general.legend.list.notAvailable.description',
  },
};

export const INITIAL_FILTERS = {
  all: false,
  [HEALTH_STATUS.CRITICAL]: false,
  [HEALTH_STATUS.ALARM]: false,
  [HEALTH_STATUS.MONITOR]: false,
  [HEALTH_STATUS.HEALTHY]: false,
  [HEALTH_STATUS.NOT_AVAILABLE]: false,
};
