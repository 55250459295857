import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'src/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from 'src/providers/AuthProvider';
import reportWebVitals from 'src/reportWebVitals';
import { worker } from 'src/mocks/browser';
import { store } from 'src/app/store';
import { theme } from 'src/theme';
import { App } from 'src/app/App';

const container = document.getElementById('root')!;
const root = createRoot(container);

worker.start({
  onUnhandledRequest: 'bypass',
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
