import React, { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { css } from '@emotion/react';

const enum VIEW_STATE {
  Failures = 'failures',
  Components = 'components',
}

export const ViewSwitcher = () => {
  const [state, setState] = useState<VIEW_STATE>(VIEW_STATE.Failures);

  return (
    <Menu>
      <MenuButton
        px={3}
        py={1}
        transition="all 0.2s"
        borderRadius="full"
        css={menuButtonStyle}
        _hover={{ bg: 'rgba(112, 113, 243, 0.12)' }}
        _expanded={{ bg: 'rgba(112, 113, 243, 0.12)' }}
      >
        {state} <ChevronDownIcon fontSize="1rem" />
      </MenuButton>
      <MenuList>
        {state === VIEW_STATE.Failures ? (
          <MenuItem onClick={() => setState(VIEW_STATE.Components)} css={menuItemStyle}>
            {VIEW_STATE.Components}
          </MenuItem>
        ) : null}
        {state === VIEW_STATE.Components ? (
          <MenuItem onClick={() => setState(VIEW_STATE.Failures)} css={menuItemStyle}>
            {VIEW_STATE.Failures}
          </MenuItem>
        ) : null}
      </MenuList>
    </Menu>
  );
};

const menuButtonStyle = css`
  font-size: 0.875rem;
  color: #7071f3;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid rgba(112, 113, 243, 0.12);
`;

const menuItemStyle = css`
  font-size: 0.875rem;
  color: #7071f3;
  font-weight: 700;
  text-transform: uppercase;
  :hover {
    background-color: rgba(112, 113, 243, 0.12);
  }
`;
