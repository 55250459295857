import React from 'react';
import { Box } from '@chakra-ui/react';
import { SettingsSidebar } from 'src/pages/settings/components/SettingsSidebar';
import { Outlet } from 'react-router-dom';

export const SettingsPage = () => {
  return (
    <Box>
      <SettingsSidebar />

      <Box pl="25.25rem" pt="4rem" pr="4rem" h="100%">
        <Outlet />
      </Box>
    </Box>
  );
};
