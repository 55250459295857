import React from 'react';
import { SitesRow } from 'src/pages/sites/components/SitesRow';
import { VStack } from '@chakra-ui/react';
import { Site } from 'src/app/services/sites';

type SitesListProps = {
  sites?: Array<Site>;
};

const SitesList = ({ sites }: SitesListProps) => {
  return (
    <VStack spacing="1rem" position="relative">
      {sites ? sites.map((item, index) => <SitesRow key={index} item={item} />) : null}
    </VStack>
  );
};

export default SitesList;
