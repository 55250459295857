import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DMExportIcon } from 'src/components/Icons';
import { ExportSelect } from 'src/components/export-drawer/components/ExportSelect';
import { ButtonNaked } from 'src/components/ButtonNaked';

export const ExportDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  return (
    <>
      <ButtonNaked label="Export" leftIcon={<DMExportIcon fontSize="1.5rem" />} onClick={onOpen} />

      <Drawer placement="right" size="sm" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton top={3} right={4} />
          <DrawerHeader>
            <HStack fontWeight="bold" fontSize="1rem">
              <DMExportIcon fontSize="1.5rem" />
              <Box>Export report</Box>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={6} alignItems="flex-start">
              <Box fontSize="0.875rem">Select your preferred report types and press download</Box>

              <ExportSelect placeholder="Order by" values={['Severity', 'Name']} />

              <Box w="full">
                <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} />
              </Box>

              <Box w="full">
                <DatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} />
              </Box>

              <Box w="full" pt={12}>
                <Button variant="data-mind" size="lg" borderRadius="full" w="full">
                  Download report
                </Button>
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
