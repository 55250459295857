export const colors = {
  black: '#021d3d',
  health: {
    critical: '#de6f62',
    alarm: '#ff891b',
    monitor: '#f1bf42',
    healthy: '#58a65c',
    notAvailable: '#021d3d',
  },
};
