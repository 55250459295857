import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { data } from 'src/data/echartsData';
import { format } from 'date-fns';
import { FUSION_TRENDS_TYPE, REGULAR_DATE_FORMAT } from 'src/const';
import { healthStatusMap } from '../const';

interface ChartProps {
  type: FUSION_TRENDS_TYPE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPointClick: (e: any) => void;
}

export const Chart = ({ type, onPointClick }: ChartProps) => {
  const commonOptions = {
    grid: { top: 36, right: 24, bottom: 96, left: 24 },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: function (value: string) {
          return format(new Date(value), REGULAR_DATE_FORMAT);
        },
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 5,
      axisLine: true,
      axisLabel: true,
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      trigger: 'item',
      borderWidth: 0,
      extraCssText: 'border-radius: 0.5rem',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formatter: (params: any) => {
        return `
          <div style="padding: 0.25rem">
            <div style="font-size: 1rem; font-weight: 500; padding-bottom: 0.5rem">
              ${format(new Date(params.data.value[0]), REGULAR_DATE_FORMAT)}
            </div>
            <div style="text-transform: uppercase; font-weight: 600; font-size: 0.625rem; color: ${params.color}">
              ${healthStatusMap[params.data.value[1]]}
            </div>
          </div>
        `;
      },
    },
    visualMap: {
      show: false,
      pieces: [
        {
          gt: 3,
          lte: 4,
          color: '#58a65c',
        },
        {
          gt: 2,
          lte: 3,
          color: '#f1bf42',
        },
        {
          gt: 1,
          lte: 2,
          color: '#ff891b',
        },
        {
          gt: 0,
          lte: 1,
          color: '#de6f62',
        },
      ],
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
  };

  const options = {
    ...commonOptions,
    series: [
      {
        type: 'line',
        smooth: false,
        data: data,
        symbol: 'circle',
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
        symbolSize: 14,
        lineStyle: {
          color: type === FUSION_TRENDS_TYPE.Component ? '#7071f3' : '#D3D3D3',
          width: 2,
        },
        ...(type === FUSION_TRENDS_TYPE.Component
          ? {
              areaStyle: {
                color: 'rgba(112, 113, 243, 0.12)',
              },
            }
          : null),
      },
    ],
  };

  return (
    <ReactECharts
      echarts={echarts}
      option={options}
      onEvents={{
        click: onPointClick,
      }}
    />
  );
};
