import React from 'react';
import { FormLayout } from 'src/pages/auth/layouts/FormLayout';
import { DMEmailConfirmedIcon } from 'src/components/Icons';
import { Button, HStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';
import { useNavigate } from 'react-router-dom';
import { locations } from 'src/app/locations';

export const PasswordChangedPage = () => {
  const navigate = useNavigate();
  const goBackToLogin = () => navigate(locations.public.login);

  return (
    <FormLayout
      title="auth.resetPassword.changed.heading"
      subtitle="auth.resetPassword.changed.description"
      icon={<DMEmailConfirmedIcon fontSize="4rem" color="health.healthy" mb={6} />}
    >
      <HStack minW="26.25rem" justifyContent="flex-end">
        <Button variant="data-mind" size="lg" borderRadius="full" onClick={goBackToLogin}>
          <FormattedText label="auth.resetPassword.changed.submit" />
        </Button>
      </HStack>
    </FormLayout>
  );
};
