import React, { useState } from 'react';
import {
  Box,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { MachineFailureRow } from 'src/pages/machines/components/MachineFailureRow';
import { useGetDiagnosticsQuery } from 'src/app/services/diagnostics';
import { ComponentDiagnostic } from 'src/data/diagnostics';
import { ButtonNaked } from 'src/components/ButtonNaked';
import { DMChartIcon, DMSitesIcon } from 'src/components/Icons';
import { FUSION_TRENDS_TYPE } from 'src/const';
import { Chart } from 'src/pages/machines/components/Chart';
import { healthStatusMap } from 'src/pages/machines/const';
import { useGetSensorsQuery } from 'src/app/api';

interface FusionTrendsModalProps {
  title: string;
  type: FUSION_TRENDS_TYPE;
}

export const FusionTrendsModal = ({ title, type }: FusionTrendsModalProps) => {
  const [statusFilter, setStatusFilter] = useState<string | undefined>(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useGetDiagnosticsQuery();
  const assetDiagnostics = data && data.assets_diagnostics[0];
  const componentsDiagnostics = assetDiagnostics ? assetDiagnostics.components_diagnostics : [];
  const componentsDiagnosticsFiltered = statusFilter
    ? componentsDiagnostics.filter((item: ComponentDiagnostic) => item.health_status === statusFilter)
    : [...componentsDiagnostics];

  const { data: sensors } = useGetSensorsQuery();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPointClick = function (e: any) {
    const {
      data: { value },
    } = e;
    const type = healthStatusMap[value[1]];
    setStatusFilter(type);
  };

  return (
    <>
      <ButtonNaked leftIcon={<DMChartIcon fontSize="1.5rem" />} label={title} onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose} size="7xl" isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent borderRadius="2xl" mx={16} pb={4} bgColor="white">
          <ModalHeader fontWeight={400} fontSize="1rem">
            {title}
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody position="relative" overflowY="auto" pt={0}>
            <Box position="sticky" top={0} bgColor="white" zIndex={9999}>
              {type === FUSION_TRENDS_TYPE.Machine ? (
                <HStack>
                  <DMSitesIcon fontSize="1.5rem" />
                  <Box fontSize="1rem" fontWeight={500}>
                    Tertiary Crusher 2
                  </Box>
                </HStack>
              ) : null}

              <Chart type={type} onPointClick={onPointClick} />
              <Divider pt={12} />
            </Box>

            <VStack w="full" spacing={0}>
              {sensors &&
                componentsDiagnosticsFiltered.map((item: ComponentDiagnostic, index: number) => (
                  <Box key={index} w="full">
                    <MachineFailureRow
                      item={item}
                      py={8}
                      sensors={sensors!.sensors_health_status}
                      isHiddenButtons
                      noShadow
                    />
                    <Divider />
                  </Box>
                ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
