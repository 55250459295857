import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { Asset, Site } from 'src/app/types';
import { AssetSensorsHealthStatus } from 'src/data/sensors';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const api = createApi({
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Sites', 'Assets', 'OrganizationSummary', 'Diagnostics', 'Sensors'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getAssets: builder.query<Array<Asset>, void>({
      query: () => ({ url: 'assets_preview' }),
      providesTags: (result = []) => [
        ...result.map(({ asset_id }) => ({ type: 'Assets', asset_id } as const)),
        { type: 'Assets' as const, id: 'LIST' },
      ],
    }),
    getSites: builder.query<Array<Site>, void>({
      query: () => ({ url: 'sites_preview' }),
      providesTags: (result = []) => [
        ...result.map(({ site_id }) => ({ type: 'Sites', site_id } as const)),
        { type: 'Sites' as const, id: 'LIST' },
      ],
    }),
    getSensors: builder.query<AssetSensorsHealthStatus, void>({
      query: () => ({ url: 'sensors_health_status' }),
      providesTags: () => [{ type: 'Sensors' }],
    }),
  }),
});

export const { useGetAssetsQuery, useGetSitesQuery, useGetSensorsQuery } = api;
