import { api } from 'src/app/api';

export type SiteHealthState = {
  critical: {
    count: number;
    potential_downtime: number;
  };
  alarm: {
    count: number;
    potential_downtime: number;
  };
  monitor: {
    count: number;
    potential_downtime: number;
  };
  healthy: {
    count: number;
    potential_downtime: number;
  };
  na: {
    count: number;
    potential_downtime: number;
  };
};

export type Site = {
  site_id: string;
  site_name: string;
  last_recorded: string;
  assets_health_state: SiteHealthState;
};

type SitesResponse = Array<Site>;

export const sitesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSites: builder.query<SitesResponse, void>({
      query: () => ({ url: 'sites_preview' }),
      providesTags: (result = []) => [
        ...result.map(({ site_id }) => ({ type: 'Sites', site_id } as const)),
        { type: 'Sites' as const, id: 'LIST' },
      ],
    }),
  }),
});

export const { useGetSitesQuery } = sitesApi;
