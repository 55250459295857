import React from 'react';
import { Avatar, AvatarBadge, Box, Button, FormControl, FormLabel, HStack, Input, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { DMImageUploadIcon } from 'src/components/Icons';
import userAvatar from 'src/assets/images/user-avatar.png';

const initialValues = {
  firstname: 'John',
  lastname: 'Doe',
  phone: '050-9876543',
  email: 'johndoe@gmail.com',
  currentPassword: 'password',
  newPassword: 'password',
  confirmPassword: 'password',
};

export const AccountSettingsPage = () => {
  return (
    <VStack alignItems="flex-start" spacing={12}>
      <Box>
        <Avatar name="John Doe" src={userAvatar} w="7rem" h="7rem">
          <AvatarBadge boxSize="2.5em" bg="#7071f3" cursor="pointer">
            <DMImageUploadIcon fontSize="1.5rem" />
          </AvatarBadge>
        </Avatar>
      </Box>

      <Box>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <VStack alignItems="flex-start" spacing={6}>
                <Box fontWeight={600}>Edit profile</Box>

                <HStack spacing={6}>
                  <FormControl variant="floating" id="firstname" minW="20rem">
                    <Input
                      type="text"
                      name="firstname"
                      variant="data-mind-auth"
                      placeholder=" "
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel>First name</FormLabel>
                  </FormControl>

                  <FormControl variant="floating" id="lastname" minW="20rem">
                    <Input
                      type="text"
                      name="lastname"
                      variant="data-mind-auth"
                      placeholder=" "
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel>Last name</FormLabel>
                  </FormControl>
                </HStack>

                <HStack spacing={6}>
                  <FormControl variant="floating" id="phone" minW="20rem">
                    <Input
                      type="text"
                      name="phone"
                      variant="data-mind-auth"
                      placeholder=" "
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel>Phone</FormLabel>
                  </FormControl>

                  <FormControl variant="floating" id="email" minW="20rem">
                    <Input
                      type="text"
                      name="email"
                      variant="data-mind-auth"
                      placeholder=" "
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel>Email</FormLabel>
                  </FormControl>
                </HStack>
              </VStack>

              <VStack alignItems="flex-start" spacing={6} pt={12}>
                <Box fontWeight={600}>Change password</Box>

                <HStack spacing={6}>
                  <FormControl variant="floating" id="currentPassword" minW="20rem">
                    <Input
                      type="password"
                      name="currentPassword"
                      variant="data-mind-auth"
                      placeholder=" "
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel>Current password</FormLabel>
                  </FormControl>
                </HStack>

                <HStack spacing={6}>
                  <FormControl variant="floating" id="newPassword" minW="20rem">
                    <Input
                      type="password"
                      name="newPassword"
                      variant="data-mind-auth"
                      placeholder=" "
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel>New password</FormLabel>
                  </FormControl>

                  <FormControl variant="floating" id="confirmPassword" minW="20rem">
                    <Input
                      type="password"
                      name="confirmPassword"
                      variant="data-mind-auth"
                      placeholder=" "
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel>Confirm password</FormLabel>
                  </FormControl>
                </HStack>
              </VStack>

              <Box pt={12}>
                <Button variant="data-mind" type="submit" size="lg" borderRadius="full" disabled={isSubmitting}>
                  Save changes
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};
