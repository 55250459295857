import { api } from 'src/app/api';

export type OrganizationSummarySite = {
  site_id: string;
  site_name: string;
  assets_count: number;
  current_potential_downtime: number;
  historical_potential_downtime: number;
  current_energy_saving: number;
  historical_energy_saving: number;
};

type OrganizationSummarySiteResponse = OrganizationSummarySite[];

export const organizationSummaryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationSummary: builder.query<OrganizationSummarySiteResponse, void>({
      query: () => ({ url: 'organization_summary' }),
      providesTags: (result = []) => [
        ...result.map(({ site_id }) => ({ type: 'OrganizationSummary', site_id } as const)),
        { type: 'OrganizationSummary' as const, id: 'LIST' },
      ],
    }),
  }),
});

export const { useGetOrganizationSummaryQuery } = organizationSummaryApi;
