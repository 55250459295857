import React, { useState } from 'react';
import { HStack } from '@chakra-ui/react';
import { DMThumbDownIcon, DMThumbUpIcon } from 'src/components/Icons';

const enum FEEDBACK_STATE {
  Up = 'up',
  Down = 'down',
}

export const FeedbackThumbs = () => {
  const [state, setState] = useState<string | null>(null);

  return (
    <HStack justifyContent="flex-end">
      <DMThumbDownIcon
        fontSize="1.5rem"
        color={state === FEEDBACK_STATE.Down ? 'health.critical' : state !== null ? 'rgba(2, 29, 61, 0.34)' : 'black'}
        onClick={() => setState(FEEDBACK_STATE.Down)}
        cursor="pointer"
      />
      <DMThumbUpIcon
        fontSize="1.5rem"
        color={state === FEEDBACK_STATE.Up ? 'health.healthy' : state !== null ? 'rgba(2, 29, 61, 0.34)' : 'black'}
        onClick={() => setState(FEEDBACK_STATE.Up)}
        cursor="pointer"
      />
    </HStack>
  );
};
