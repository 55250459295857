import React, { useEffect } from 'react';
import { HEALTH_STATUS, INITIAL_FILTERS } from 'src/const';
import { FilterButton } from 'src/components/filter-bar/components/FilterButton';

type StatusFilterProps = {
  selectedStatuses: Record<string, boolean>;
  setSelectedStatuses: (state: Record<string, boolean>) => void;
};

export const StatusFilter = ({ selectedStatuses, setSelectedStatuses }: StatusFilterProps) => {
  const onFilterButtonClick = (type: string) => {
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      [type]: true,
    });
  };

  useEffect(() => {
    setSelectedStatuses({
      ...INITIAL_FILTERS,
      all: true,
    });
  }, []);

  return (
    <>
      <FilterButton
        active={selectedStatuses.all}
        amount={52}
        label="Show all"
        onClick={() => onFilterButtonClick('all')}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.CRITICAL]}
        amount={2}
        label={HEALTH_STATUS.CRITICAL.toUpperCase()}
        status={HEALTH_STATUS.CRITICAL}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.CRITICAL)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.ALARM]}
        amount={1}
        label={HEALTH_STATUS.ALARM.toUpperCase()}
        status={HEALTH_STATUS.ALARM}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.ALARM)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.MONITOR]}
        amount={1}
        label={HEALTH_STATUS.MONITOR.toUpperCase()}
        status={HEALTH_STATUS.MONITOR}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.MONITOR)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.HEALTHY]}
        amount={30}
        label={HEALTH_STATUS.HEALTHY.toUpperCase()}
        status={HEALTH_STATUS.HEALTHY}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.HEALTHY)}
      />
      <FilterButton
        active={selectedStatuses[HEALTH_STATUS.NOT_AVAILABLE]}
        amount={8}
        label="N/A"
        status={HEALTH_STATUS.NOT_AVAILABLE}
        onClick={() => onFilterButtonClick(HEALTH_STATUS.NOT_AVAILABLE)}
      />
    </>
  );
};
