import React from 'react';
import { FormLayout } from 'src/pages/auth/layouts/FormLayout';
import ChangePasswordForm from 'src/pages/auth/components/ChangePasswordForm';

export const ChangePasswordPage = () => {
  return (
    <FormLayout title="auth.resetPassword.change.heading" subtitle="auth.resetPassword.change.description">
      <ChangePasswordForm />
    </FormLayout>
  );
};
