import React from 'react';
import { DMMachineIcon } from 'src/components/Icons';
import { Box, HStack, SimpleGrid } from '@chakra-ui/react';
import { Asset } from 'src/app/types';
import { MachineItem } from 'src/pages/machines/components/MachineItem';
import { FormattedText } from 'src/components/FormattedText';

type MachinesGroupProps = {
  title: string;
  assets: Array<Asset>;
  isRequireAttention?: boolean;
};

export const MachinesGroup = ({ title, assets, isRequireAttention }: MachinesGroupProps) => {
  return (
    <Box w="full">
      <HStack color="rgb(100,115,134)" fontSize="0.875rem" fontWeight={600} mb={4}>
        <DMMachineIcon fontSize="1.5rem" />
        <Box>
          <FormattedText label={title} values={{ count: assets.length }} />
        </Box>
      </HStack>

      <SimpleGrid columns={[null, null, 2, 2, 3, 4]} spacing={4}>
        {assets.map((item, index) => (
          <MachineItem key={index} item={item} isRequireAttention={isRequireAttention} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
