import React from 'react';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { DMSettingsIcon, DMTuneIcon, DMUserIcon, DMUsersIcon } from 'src/components/Icons';
import { FormattedText } from 'src/components/FormattedText';
import { NavLink } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { css } from '@emotion/react';

export function SettingsSidebar() {
  return (
    <VStack
      w="21.25rem"
      bgColor="#fcfcfc"
      p={6}
      spacing={6}
      position="fixed"
      top="3.5rem"
      height="100%"
      boxShadow="md"
      alignItems="flex-start"
    >
      <HStack>
        <DMSettingsIcon fontSize="1.5rem" />
        <Box fontWeight={500}>Settings</Box>
      </HStack>

      <VStack w="full">
        <Box w="full">
          <NavLink to={locations.protected.settings.account}>
            {({ isActive }) => (
              <Button
                leftIcon={<DMUserIcon fontSize="1.5rem" />}
                variant="outline"
                size="lg"
                borderRadius="lg"
                css={{ ...sidebarButtonStyle, ...(isActive ? sidebarButtonActiveStyle : null) }}
              >
                <FormattedText label="page.settings.sidebar.accountSettings" />
              </Button>
            )}
          </NavLink>
        </Box>

        <Box w="full">
          <Button
            leftIcon={<DMUsersIcon fontSize="1.5rem" />}
            variant="outline"
            size="lg"
            borderRadius="lg"
            css={{ ...sidebarButtonStyle }}
            isDisabled
          >
            <FormattedText label="page.settings.sidebar.userManagement" />
          </Button>
        </Box>

        <Box w="full">
          <NavLink to={locations.protected.settings.preferences}>
            {({ isActive }) => (
              <Button
                leftIcon={<DMTuneIcon fontSize="1.5rem" />}
                variant="outline"
                size="lg"
                borderRadius="lg"
                css={{ ...sidebarButtonStyle, ...(isActive ? sidebarButtonActiveStyle : null) }}
              >
                <FormattedText label="page.settings.sidebar.preferences" />
              </Button>
            )}
          </NavLink>
        </Box>
      </VStack>
    </VStack>
  );
}

const sidebarButtonStyle = css`
  width: 100%;
  font-size: 0.875rem;
  justify-content: flex-start;
`;

const sidebarButtonActiveStyle = css`
  width: 100%;
  font-size: 0.875rem;
  justify-content: flex-start;
  border: 2px solid #7071f3;
`;
