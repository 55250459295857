import React from 'react';
import { Box } from '@chakra-ui/react';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import dataMindLogo from 'src/assets/images/data-mind-logo.svg';
import razorLabsLogo from 'src/assets/images/razor-labs-logo.svg';
import appIcon from 'src/assets/images/appicon@3x.png';

export function AppLogo({ size }: { size: string }) {
  return (
    <Box>
      <img src={dataMindLogo} alt="DataMind" width={size} />
    </Box>
  );
}

export const RazorLabsLogo = () => {
  return (
    <Box>
      <img src={razorLabsLogo} alt="DataMind" width="353px" />
    </Box>
  );
};

export const AppIcon = () => {
  return (
    <Box mt={4} mb={4}>
      <img src={appIcon} alt="DataMind" width={SIDEBAR_MENU_BUTTON_SIZE} />
    </Box>
  );
};
