import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMFileIcon } from 'src/components/Icons';
import { DowntimeDataView } from 'src/components/DowntimeDataView';
import { Agreement } from 'src/components/Agreement';

const activeStyle = css`
  :active,
  :hover {
    color: #12ffe2;
  }
`;

export const BusinessReview = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCloseHandler = () => {
    onClose();
    navigate(-1);
  };

  return (
    <>
      <NavLink to="#business-review">
        <IconButton
          aria-label="Business review"
          size="lg"
          css={activeStyle}
          icon={<DMFileIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
          onClick={onOpen}
          variant="unstyled"
        />
      </NavLink>

      <Modal isOpen={isOpen} onClose={onCloseHandler} size="6xl" scrollBehavior="outside" isCentered>
        <ModalOverlay />
        <ModalContent pb={4} borderRadius="2xl">
          <ModalHeader fontWeight={400} fontSize="1rem">
            Business review
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Tabs isFitted variant="data-mind" h="full">
              <TabList>
                <Tab>Downtime</Tab>
                <Tab>Agreement</Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0}>
                  <DowntimeDataView />
                </TabPanel>
                <TabPanel>
                  <Agreement />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
