import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { Box, Container, HStack } from '@chakra-ui/react';
import { Sidebar } from 'src/components/Sidebar';
import { Header } from 'src/components/Header';
import { locations } from 'src/app/locations';
import { useAuth, useUserContext } from 'src/providers/AuthProvider';

export const AppLayout = () => {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to={locations.public.login} state={{ from: location }} replace />;
  }

  return (
    <Container css={containerStyle}>
      <HStack w="full" spacing={0}>
        <Sidebar />

        <Box w="full" h="100vh">
          <Header />
          <Box css={outletBoxStyle}>
            <Outlet />
          </Box>
        </Box>
      </HStack>
    </Container>
  );
};

const containerStyle = css`
  width: 100%;
  min-width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f7f7f7;
`;

const outletBoxStyle = css`
  width: 100%;
  padding: 3.5rem 0 0 3.5rem;
  background-color: #f7f7f7;
`;
