import { DefaultBodyType, PathParams, ResponseComposition, rest, RestContext, RestRequest } from 'msw';
import { sites } from 'src/data/sites';
import { assets } from 'src/data/assets';
import { organizationSummary } from 'src/data/organizationSummary';
import { diagnostics } from 'src/data/diagnostics';
import { alerts } from 'src/data/alerts';
import { getAssetSensorsHealthStatus } from 'src/data/sensors';

export const handlers = [
  rest.get(
    '/api/sites_preview',
    (req: RestRequest<never, PathParams<string>>, res: ResponseComposition<DefaultBodyType>, ctx: RestContext) => {
      return res(ctx.status(200), ctx.json(sites));
    }
  ),

  rest.get(
    '/api/assets_preview',
    (req: RestRequest<never, PathParams<string>>, res: ResponseComposition<DefaultBodyType>, ctx: RestContext) => {
      return res(ctx.status(200), ctx.json(assets));
    }
  ),

  rest.get(
    '/api/alerts',
    (req: RestRequest<never, PathParams<string>>, res: ResponseComposition<DefaultBodyType>, ctx: RestContext) => {
      return res(ctx.status(200), ctx.json(alerts));
    }
  ),

  rest.get(
    '/api/organization_summary',
    (req: RestRequest<never, PathParams<string>>, res: ResponseComposition<DefaultBodyType>, ctx: RestContext) => {
      return res(ctx.status(200), ctx.json(organizationSummary));
    }
  ),

  rest.get(
    '/api/diagnostics',
    (req: RestRequest<never, PathParams<string>>, res: ResponseComposition<DefaultBodyType>, ctx: RestContext) => {
      return res(ctx.status(200), ctx.json(diagnostics));
    }
  ),

  rest.get(
    '/api/sensors_health_status',
    (req: RestRequest<never, PathParams<string>>, res: ResponseComposition<DefaultBodyType>, ctx: RestContext) => {
      return res(ctx.status(200), ctx.json(getAssetSensorsHealthStatus()));
    }
  ),
];
