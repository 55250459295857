import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';
import { useSticky } from 'src/hooks/useSticky';
import { css } from '@emotion/react';

const SitesHeader = () => {
  const [sticky] = useSticky();

  const style = {
    ...sitesHeaderBaseStyle,
    ...(sticky ? sitesHeaderStickyStyle : null),
  };

  return (
    <HStack alignItems="flex-start" justifyContent="space-between" textTransform="uppercase" css={style}>
      <Box>Site name</Box>
      <HStack spacing={8} alignItems="flex-start" textAlign="left">
        <Box w="4rem">Equipment</Box>
        <Box w="4rem">{HEALTH_STATUS.CRITICAL}</Box>
        <Box w="4rem">{HEALTH_STATUS.ALARM}</Box>
        <Box w="4rem">{HEALTH_STATUS.MONITOR}</Box>
        <Box w="4rem">{HEALTH_STATUS.HEALTHY}</Box>
        <Box w="4rem">N/A</Box>
      </HStack>
    </HStack>
  );
};

export default SitesHeader;

const sitesHeaderBaseStyle = css`
  width: 100%;
  font-size: 0.625rem;
  font-weight: 600;
  height: 3rem;
  padding: 1rem;
  background-color: transparent;
`;

const sitesHeaderStickyStyle = css`
  position: fixed;
  top: 7.5rem;
  left: 0;
  z-index: 1;
  width: 100%;
  font-size: 0.625rem;
  font-weight: 600;
  padding: 0.5rem 26.25rem 0.5rem 8.5rem;
  background-color: #eaebee;
`;
