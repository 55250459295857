import React from 'react';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, VStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';

type ChangePasswordFormData = {
  password: string;
  confirmPassword: string;
};

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const { changePassword } = useAuth();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ChangePasswordFormData>();

  const onSubmit = async (values: FieldValues) => {
    const { password } = values;

    if (isValid && oobCode) {
      try {
        await changePassword(oobCode, password);
        navigate(locations.public.changed, { replace: true });
      } catch (error: unknown) {
        console.log(error);
      }
    }
  };

  return (
    <Box minW="26.25rem">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <FormControl variant="floating" isInvalid={!!errors.password}>
            <Input
              type="password"
              variant="data-mind-auth"
              placeholder=" "
              {...register('password', {
                required: 'Password is required',
              })}
            />
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            <FormLabel>
              <FormattedText label="auth.login.form.password.label" />
            </FormLabel>
          </FormControl>

          <FormControl variant="floating" isInvalid={!!errors.password}>
            <Input
              type="password"
              variant="data-mind-auth"
              placeholder=" "
              {...register('confirmPassword', {
                required: 'Password is required',
                validate: (value: string) => {
                  if (watch('password') !== value) {
                    return 'Your passwords do not match';
                  }
                },
              })}
            />
            <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
            <FormLabel>
              <FormattedText label="auth.login.form.password.label" />
            </FormLabel>
          </FormControl>
        </VStack>

        <HStack mt={8} justifyContent="space-between" spacing={5}>
          <Box w="full">
            <NavLink to={locations.public.login}>
              <Button variant="data-mind-naked">
                <FormattedText label="auth.resetPassword.change.form.back" />
              </Button>
            </NavLink>
          </Box>

          <Box w="full">
            <Button w="full" type="submit" variant="data-mind" size="lg" borderRadius="full" isLoading={isSubmitting}>
              <FormattedText label="auth.resetPassword.change.form.submit" />
            </Button>
          </Box>
        </HStack>
      </form>
    </Box>
  );
};

export default ChangePasswordForm;
