import { faker } from '@faker-js/faker';
import { HEALTH_STATUS } from 'src/const';

export type SensorHealthStatus = {
  last_uptime: string;
  sensor_name: string;
  sensor_type: string;
  health_status: string;
  located_at: string;
};

export type AssetSensorsHealthStatus = {
  asset_id: string;
  sensors_health_status: Array<SensorHealthStatus>;
};

export const getAssetSensorsHealthStatus = (): AssetSensorsHealthStatus => {
  return {
    asset_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    sensors_health_status: faker.helpers.uniqueArray(
      () => ({
        last_uptime: 'string',
        sensor_name: 'string',
        sensor_type: 'string',
        health_status: HEALTH_STATUS.HEALTHY,
        located_at: 'string',
      }),
      8
    ),
  };
};
