import React from 'react';
import { Box } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { NavigationMenu } from 'src/components/NavigationMenu';

export const Sidebar = () => {
  return (
    <Box css={style}>
      <NavigationMenu />
    </Box>
  );
};

const style = css`
  width: 3.5rem;
  height: 100%;
  position: fixed;
  color: #fffefe;
  z-index: 999;
  background-color: #2f2f66;
`;
