import React, { useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { MachineFailureRow } from 'src/pages/machines/components/MachineFailureRow';
import { Toolbar } from 'src/components/Toolbar';
import { FilterBar } from 'src/components/filter-bar/FilterBar';
import { useGetDiagnosticsQuery } from 'src/app/services/diagnostics';
import { INITIAL_FILTERS } from 'src/const';
import { useGetSensorsQuery } from 'src/app/api';

export const MachineFailuresPage = () => {
  const [selectedSensors, setSelectedSensors] = useState<string>('all');
  const [selectedStatuses, setSelectedStatuses] = useState<Record<string, boolean>>(INITIAL_FILTERS);
  const selectedStatusesKeys = Object.keys(selectedStatuses).filter((key) => selectedStatuses[key]);

  const { data: diagnostics } = useGetDiagnosticsQuery(undefined, {
    selectFromResult: ({ data, error, isLoading }) => ({
      data: (() => {
        const assetDiagnostics = data && data.assets_diagnostics[0];
        const componentsDiagnostics = assetDiagnostics ? assetDiagnostics.components_diagnostics : [];

        if (!selectedStatuses.all) {
          return componentsDiagnostics?.filter((item) => item.health_status === selectedStatusesKeys[0]);
        } else {
          return componentsDiagnostics;
        }
      })(),
      error,
      isLoading,
    }),
  });

  const { data: sensors } = useGetSensorsQuery();

  return (
    <Box w="full" p="4rem" pt="9rem">
      <Toolbar siteName="Sunrise Dam Gold Mine" machineName="Tertiary Crusher 2" isTopFixed />
      <FilterBar
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        selectedSensors={selectedSensors}
        setSelectedSensors={setSelectedSensors}
      />

      <VStack w="full" spacing={4}>
        {diagnostics &&
          sensors &&
          diagnostics.map((item, index) => (
            <MachineFailureRow key={index} item={item} sensors={sensors!.sensors_health_status} />
          ))}
      </VStack>
    </Box>
  );
};
