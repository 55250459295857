import { faker } from '@faker-js/faker';
import { Asset } from 'src/app/types';
import { HEALTH_STATUS } from 'src/const';

const criticalAssets: Array<Asset> = faker.helpers.uniqueArray(
  () => ({
    asset_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    asset_name: `Tertiary Crusher ${faker.datatype.number({ min: 10, max: 99 }).toString()}`,
    last_recorded: faker.date.recent().toISOString(),
    health_status: HEALTH_STATUS.CRITICAL,
    potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
    energy_saving: faker.datatype.number({ min: 10, max: 99 }),
    most_severe_diagnostics: [],
    overall_sensors_status: HEALTH_STATUS.CRITICAL,
    sensors_health_status: faker.helpers.uniqueArray(
      () => ({
        last_uptime: 'string',
        sensor_name: 'string',
        sensor_type: 'string',
        health_status: HEALTH_STATUS.CRITICAL,
        located_at: 'string',
      }),
      8
    ),
  }),
  2
);

const alarmAssets: Array<Asset> = faker.helpers.uniqueArray(
  () => ({
    asset_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    asset_name: `Tertiary Crusher ${faker.datatype.number({ min: 10, max: 99 }).toString()}`,
    last_recorded: faker.date.recent().toISOString(),
    health_status: HEALTH_STATUS.ALARM,
    potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
    energy_saving: faker.datatype.number({ min: 10, max: 99 }),
    most_severe_diagnostics: [],
    overall_sensors_status: HEALTH_STATUS.CRITICAL,
    sensors_health_status: faker.helpers.uniqueArray(
      () => ({
        last_uptime: 'string',
        sensor_name: 'string',
        sensor_type: 'string',
        health_status: HEALTH_STATUS.CRITICAL,
        located_at: 'string',
      }),
      8
    ),
  }),
  2
);

const monitorAssets: Array<Asset> = faker.helpers.uniqueArray(
  () => ({
    asset_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    asset_name: `Tertiary Crusher ${faker.datatype.number({ min: 10, max: 99 }).toString()}`,
    last_recorded: faker.date.recent().toISOString(),
    health_status: HEALTH_STATUS.MONITOR,
    potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
    energy_saving: faker.datatype.number({ min: 10, max: 99 }),
    most_severe_diagnostics: [],
    overall_sensors_status: HEALTH_STATUS.CRITICAL,
    sensors_health_status: faker.helpers.uniqueArray(
      () => ({
        last_uptime: 'string',
        sensor_name: 'string',
        sensor_type: 'string',
        health_status: HEALTH_STATUS.CRITICAL,
        located_at: 'string',
      }),
      8
    ),
  }),
  2
);

const healthyAssets: Array<Asset> = faker.helpers.uniqueArray(
  () => ({
    asset_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    asset_name: `Tertiary Crusher ${faker.datatype.number({ min: 10, max: 99 }).toString()}`,
    last_recorded: faker.date.recent().toISOString(),
    health_status: HEALTH_STATUS.HEALTHY,
    potential_downtime: faker.datatype.number({ min: 10, max: 99 }),
    energy_saving: faker.datatype.number({ min: 10, max: 99 }),
    most_severe_diagnostics: [],
    overall_sensors_status: HEALTH_STATUS.HEALTHY,
    sensors_health_status: faker.helpers.uniqueArray(
      () => ({
        last_uptime: 'string',
        sensor_name: 'string',
        sensor_type: 'string',
        health_status: HEALTH_STATUS.HEALTHY,
        located_at: 'string',
      }),
      8
    ),
  }),
  18
);

export const assets = [...criticalAssets, ...alarmAssets, ...monitorAssets, ...healthyAssets];
