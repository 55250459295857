import React from 'react';
import { Box } from '@chakra-ui/react';

type SummaryChartTotalProps = {
  total: number;
};

export const SummaryChartTotal = ({ total }: SummaryChartTotalProps) => (
  <Box fontWeight={600} textAlign="center" position="absolute" top="3rem" left="4.7rem">
    <Box fontSize="2rem">{total}</Box>
    <Box fontSize="0.75rem" fontWeight={600}>
      Total
      <br />
      Machines
    </Box>
  </Box>
);
