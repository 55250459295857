import React from 'react';
import { ResetPasswordForm } from 'src/pages/auth/components/ResetPasswordForm';
import { FormLayout } from 'src/pages/auth/layouts/FormLayout';

export const ResetPasswordPage = () => {
  return (
    <FormLayout title="auth.resetPassword.email.heading" subtitle="auth.resetPassword.email.description">
      <ResetPasswordForm />
    </FormLayout>
  );
};
