import React from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { alerts } from 'src/data/alerts';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMNotificationsIcon } from 'src/components/Icons';
import { AlertsList } from 'src/components/AlertsList';
import { NotificationsPreferences } from 'src/components/NotificationsPreferences';

const Notifications = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Notifications"
        size="lg"
        variant="unstyled"
        onClick={onOpen}
        icon={<DMNotificationsIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
      />

      <Drawer isOpen={isOpen} placement="left" size="sm" onClose={onClose}>
        <DrawerOverlay ml="3.5rem" />
        <DrawerContent ml="3.5rem">
          <DrawerCloseButton top={3} right={4} />
          <DrawerHeader>
            <Box fontWeight="bold" fontSize="1rem">
              Notifications
            </Box>
          </DrawerHeader>

          <DrawerBody pl={0} pr={0} pt={0}>
            <Tabs isFitted variant="data-mind" h="full">
              <TabList>
                <Tab>Alerts</Tab>
                <Tab>Preferences</Tab>
              </TabList>

              <TabPanels h="full">
                <TabPanel p={6} pt={2}>
                  <AlertsList alerts={alerts} />
                </TabPanel>
                <TabPanel p={6} h="full">
                  <NotificationsPreferences />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Notifications;
