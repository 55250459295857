import React from 'react';
import { css } from '@emotion/react';
import { Box, HStack } from '@chakra-ui/react';
import { AppLogo } from 'src/components/Logo';
import { UserMenu } from 'src/components/UserMenu';
import { NavLink } from 'react-router-dom';

export const Header = () => {
  return (
    <HStack css={style}>
      <Box>
        <NavLink to="/">
          <AppLogo size="110px" />
        </NavLink>
      </Box>

      <UserMenu />
    </HStack>
  );
};

const style = css`
  justify-content: space-between;
  width: 100%;
  height: 3.5rem;
  min-height: 3.5rem;
  position: fixed;
  background-color: #ffffff;
  padding-left: 7.5rem;
  padding-right: 3.5rem;
  border-bottom: 1px solid rgba(1, 10, 9, 0.08);
  z-index: 4;
`;
