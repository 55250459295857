import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { HEALTH_STATUS, SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMInfoCircleIcon } from 'src/components/Icons';
import { FormattedText } from 'src/components/FormattedText';
import { HealthStatusIndicator } from 'src/components/StateIndicator';

export const LegendPopover = () => {
  const navigate = useNavigate();

  const onCloseHandler = () => navigate(-1);

  return (
    <Popover placement="right" onClose={onCloseHandler}>
      <PopoverTrigger>
        <NavLink to="#legend">
          <IconButton
            aria-label="Info"
            size="lg"
            variant="unstyled"
            icon={<DMInfoCircleIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} />}
          />
        </NavLink>
      </PopoverTrigger>

      <PopoverContent w="458px" color="#000" bottom={4} left={3}>
        <PopoverCloseButton top={6} right={6} />
        <PopoverBody p={6}>
          <Box textStyle="subtitleBold" mb={6}>
            <FormattedText label="general.legend.title" />
          </Box>

          <VStack alignItems="flex-start" spacing={4}>
            <HealthStatusIndicator type={HEALTH_STATUS.CRITICAL} showDescription mb={1} />
            <HealthStatusIndicator type={HEALTH_STATUS.ALARM} showDescription />
            <HealthStatusIndicator type={HEALTH_STATUS.MONITOR} showDescription />
            <HealthStatusIndicator type={HEALTH_STATUS.HEALTHY} showDescription />
            <HealthStatusIndicator type={HEALTH_STATUS.NOT_AVAILABLE} showDescription />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
