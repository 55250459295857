import React from 'react';
import { Input, InputGroup, InputGroupProps, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

interface SearchControlProps extends InputGroupProps {
  isTopFixed: boolean;
  sticky: boolean;
}

export const SearchControl = ({ isTopFixed, sticky, ...rest }: SearchControlProps) => {
  const { t } = useTranslation();

  return (
    <InputGroup size="sm" w="20rem" variant="data-mind-search" mr={4} {...rest}>
      <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
      <Input
        type="text"
        className="search-input"
        placeholder={t('general.searchControl.placeholder').toString()}
        bgColor={!isTopFixed && sticky ? 'rgba(2, 29, 61, 0.04)' : ''}
      />
    </InputGroup>
  );
};
