import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { SensorsFilterDropdown } from 'src/components/SensorsFilterDropdown';
import { FormattedText } from 'src/components/FormattedText';
import { EnergySaving } from 'src/components/filter-bar/components/EnergySaving';
import { Downtime } from 'src/components/filter-bar/components/Downtime';
import { StatusFilter } from 'src/components/filter-bar/components/StatusFilter';
import { useMatch } from 'react-router-dom';
import { locations } from 'src/app/locations';

interface FilterBarProps {
  selectedStatuses: Record<string, boolean>;
  setSelectedStatuses: (state: Record<string, boolean>) => void;
  selectedSensors: string;
  setSelectedSensors: (state: string) => void;
}

export const FilterBar = ({
  selectedStatuses,
  setSelectedStatuses,
  selectedSensors,
  setSelectedSensors,
}: FilterBarProps) => {
  const matchFailuresLocation = useMatch(locations.protected.sites.failures);

  return (
    <HStack css={filterBarStyle}>
      <HStack>
        <Box fontSize="0.75rem" pr={4}>
          <FormattedText label="general.filterBar.title" />
        </Box>

        <HStack spacing={0}>
          <StatusFilter selectedStatuses={selectedStatuses} setSelectedStatuses={setSelectedStatuses} />
          {matchFailuresLocation ? null : (
            <SensorsFilterDropdown selectedSensors={selectedSensors} setSelectedSensors={setSelectedSensors} />
          )}
        </HStack>
      </HStack>

      <HStack spacing={6}>
        <EnergySaving />
        <Downtime />
      </HStack>
    </HStack>
  );
};

const filterBarStyle = css`
  position: fixed;
  top: 7.5rem;
  left: 0;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  height: 3rem;
  background-color: #fff;
  padding-left: 7.5rem;
  padding-right: 4rem;
  border-bottom: 1px solid rgba(1, 10, 9, 0.1);
`;
