import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, VStack } from '@chakra-ui/react';
import { FormattedText } from 'src/components/FormattedText';
import { useAuth } from 'src/providers/AuthProvider';
import { locations } from 'src/app/locations';

type LoginFormData = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<LoginFormData>();

  const onSubmit = async (values: FieldValues) => {
    const { email, password } = values;

    if (isValid) {
      try {
        await signIn(email, password);
        navigate(locations.protected.sites.index);
      } catch (error: unknown) {
        console.log(error);
      }
    }
  };

  return (
    <Box minW="26.25rem">
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={8}>
          <FormControl variant="floating" isInvalid={!!errors.email}>
            <Input
              type="text"
              variant="data-mind-auth"
              placeholder=" "
              {...register('email', {
                required: 'Email is required',
              })}
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            <FormLabel>
              <FormattedText label="auth.login.form.email.label" />
            </FormLabel>
          </FormControl>

          <FormControl variant="floating" isInvalid={!!errors.password}>
            <Input
              type="password"
              variant="data-mind-auth"
              placeholder=" "
              {...register('password', {
                required: 'Password is required',
              })}
            />
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            <FormLabel>
              <FormattedText label="auth.login.form.password.label" />
            </FormLabel>
          </FormControl>
        </VStack>

        <HStack mt={8} justifyContent="space-between" spacing={5}>
          <Box w="full">
            <NavLink to="/reset-password">
              <Button variant="data-mind-naked">
                <FormattedText label="auth.login.form.resetPassword" />
              </Button>
            </NavLink>
          </Box>

          <Box w="full">
            <Button w="full" type="submit" variant="data-mind" size="lg" borderRadius="full" isLoading={isSubmitting}>
              <FormattedText label="auth.login.form.submit" />
            </Button>
          </Box>
        </HStack>
      </form>
    </Box>
  );
};
