import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { AppLayout } from 'src/layouts/AppLayout';
import { AuthLayout } from 'src/layouts/AuthLayout';

import {
  LoginPage,
  ResetPasswordPage,
  ResetLinkSentPage,
  ChangePasswordPage,
  PasswordChangedPage,
} from 'src/pages/auth';

import { SitesPages } from 'src/pages/sites/SitesPage';
import { MachinesPage } from 'src/pages/machines/MachinesPage';
import { MachineFailuresPage } from 'src/pages/machines/MachineFailuresPage';
import { SettingsPage } from 'src/pages/settings/SettingsPage';
import { AccountSettingsPage } from 'src/pages/settings/pages/AccountSettingsPage';
import { UserManagementPage } from 'src/pages/settings/pages/UserManagementPage';
import { PreferencesPage } from 'src/pages/settings/pages/PreferencesPage';
import { NoMatchPage } from 'src/pages/NoMatchPage';

export const AppRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={locations.public.login} element={<LoginPage />} />
        <Route path={locations.public.resetPassword} element={<ResetPasswordPage />} />
        <Route path={locations.public.sent} element={<ResetLinkSentPage />} />
        <Route path={locations.public.change} element={<ChangePasswordPage />} />
        <Route path={locations.public.changed} element={<PasswordChangedPage />} />
        <Route path={locations.fallback} element={<LoginPage />} />
      </Route>

      <Route path={locations.base} element={<AppLayout />}>
        <Route index element={<Navigate to={locations.protected.sites.index} />} />
        <Route path={locations.protected.sites.index} element={<SitesPages />} />
        <Route path={locations.protected.sites.machines} element={<MachinesPage />} />
        <Route path={locations.protected.sites.failures} element={<MachineFailuresPage />} />

        <Route path={locations.protected.settings.index} element={<SettingsPage />}>
          <Route index element={<Navigate to={locations.protected.settings.account} />} />
          <Route path={locations.protected.settings.account} element={<AccountSettingsPage />} />
          <Route path={locations.protected.settings.userManagement} element={<UserManagementPage />} />
          <Route path={locations.protected.settings.preferences} element={<PreferencesPage />} />
        </Route>

        <Route path={locations.fallback} element={<NoMatchPage />} />
      </Route>
    </Routes>
  );
};
