import { faker } from '@faker-js/faker';
import { Site } from 'src/app/services/sites';

const generateCount = () => faker.datatype.number({ min: 0, max: 12 });
const generateDowntime = () => faker.datatype.number({ min: 1111, max: 9999 });

export const sites: Array<Site> = faker.helpers.uniqueArray(
  () => ({
    site_id: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
    site_name: `${faker.commerce.productName()} Mine`,
    last_recorded: faker.date.recent().toISOString(),
    assets_health_state: {
      critical: {
        count: generateCount(),
        potential_downtime: generateDowntime(),
      },
      alarm: {
        count: generateCount(),
        potential_downtime: generateDowntime(),
      },
      monitor: {
        count: generateCount(),
        potential_downtime: generateDowntime(),
      },
      healthy: {
        count: generateCount(),
        potential_downtime: generateDowntime(),
      },
      na: {
        count: generateCount(),
        potential_downtime: generateDowntime(),
      },
    },
  }),
  32
);
