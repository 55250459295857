import React from 'react';
import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { DMMapIcon } from 'src/components/Icons';
import sitemapImage from 'src/assets/images/sitemap-image.png';
import { ButtonNaked } from 'src/components/ButtonNaked';

export const SitemapModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ButtonNaked label="Sitemap" leftIcon={<DMMapIcon fontSize="1.5rem" />} onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl" maxW="calc(100% - 10rem)">
          <ModalHeader fontWeight={400} fontSize="1rem">
            Sitemap
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody p={0}>
            <Image src={sitemapImage} borderBottomLeftRadius="2xl" borderBottomRightRadius="2xl" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
