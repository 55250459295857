import React from 'react';
import { Select } from '@chakra-ui/react';

interface ExportSelectProps {
  placeholder: string;
  values: string[] | undefined;
}

export const ExportSelect = ({ values, placeholder }: ExportSelectProps) => {
  return (
    <Select
      w="full"
      border={0}
      height="3.5rem"
      bg="rgba(112, 113, 243, 0.07)"
      borderBottom="0.125rem solid rgba(112, 113, 243, 0.12)"
      borderBottomRadius={0}
      placeholder={placeholder}
    >
      {values
        ? values.map((item: string, index: number) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))
        : null}
    </Select>
  );
};
