import React from 'react';
import { Box, HStack, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { DMArrowRightIcon, DMSensorsIcon } from 'src/components/Icons';
import { HEALTH_STATUS } from 'src/const';

interface StatusFilterProps {
  selectedSensors: string;
  setSelectedSensors: (state: string) => void;
}

export const SensorsFilterDropdown = ({ selectedSensors, setSelectedSensors }: StatusFilterProps) => {
  const onChange = (value: string | string[]) => {
    setSelectedSensors(String(value));
  };

  return (
    <Menu gutter={0}>
      <MenuButton h="3rem">
        <Box display="flex" px={4} alignItems="center">
          <DMSensorsIcon fontSize="1.5rem" mr={2} />
          <Box fontSize="0.625rem" textTransform="uppercase" fontWeight={500} letterSpacing={1.1}>
            128 Sensors
          </Box>
          <DMArrowRightIcon fontSize="1rem" />
        </Box>
      </MenuButton>

      <MenuList borderTopRadius={0} pt={0}>
        <MenuOptionGroup type="radio">
          <MenuItemOption
            h="3rem"
            _hover={{ bg: 'rgba(112, 113, 243, 0.12)' }}
            icon={null}
            onClick={() => onChange('all')}
            isChecked={selectedSensors === 'all'}
          >
            <HStack justifyContent="space-between" w="full">
              <HStack>
                <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="black" />
                <Box fontSize="0.875rem" fontWeight={500}>
                  Show all
                </Box>
              </HStack>
              <Box fontSize="0.875rem" fontWeight={500}>
                128
              </Box>
            </HStack>
          </MenuItemOption>

          <MenuItemOption
            h="3rem"
            _hover={{ bg: 'rgba(112, 113, 243, 0.12)' }}
            icon={null}
            onClick={() => onChange(HEALTH_STATUS.CRITICAL)}
            isChecked={selectedSensors === HEALTH_STATUS.CRITICAL}
          >
            <HStack justifyContent="space-between" w="full">
              <HStack>
                <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="health.critical" />
                <Box fontSize="0.875rem" fontWeight={500}>
                  Critical sensors
                </Box>
              </HStack>
              <Box fontSize="0.875rem" fontWeight={500}>
                128
              </Box>
            </HStack>
          </MenuItemOption>

          <MenuItemOption
            h="3rem"
            _hover={{ bg: 'rgba(112, 113, 243, 0.12)' }}
            icon={null}
            onClick={() => onChange(HEALTH_STATUS.HEALTHY)}
            isChecked={selectedSensors === HEALTH_STATUS.HEALTHY}
          >
            <HStack justifyContent="space-between" w="full">
              <HStack>
                <DMSensorsIcon fontSize="1.5rem" ml={1} mr={2} color="health.healthy" />
                <Box fontSize="0.875rem" fontWeight={500}>
                  Healthy sensors
                </Box>
              </HStack>
              <Box fontSize="0.875rem" fontWeight={500}>
                128
              </Box>
            </HStack>
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
