import React from 'react';
import { Box } from '@chakra-ui/react';

export const SummaryHeader = () => {
  return (
    <Box textAlign="center" mb={16}>
      <Box fontSize="1.25rem" fontWeight={500} mb={1}>
        Summary
      </Box>
    </Box>
  );
};
