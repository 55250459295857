import React from 'react';
import { Box, Heading, HStack, VStack } from '@chakra-ui/react';
import { LoginForm } from 'src/pages/auth/components/LoginForm';
import { AppLogo } from 'src/components/Logo';
import { FormattedText } from 'src/components/FormattedText';

export const LoginPage = () => {
  return (
    <VStack h="full" justifyContent="center">
      <Box>
        <HStack spacing={3}>
          <Heading fontSize="1.5rem" fontWeight={600}>
            <FormattedText label="auth.login.heading" />
          </Heading>
          <AppLogo size="136px" />
        </HStack>

        <Box pt={6} pb={10}>
          <FormattedText label="auth.login.description" />
        </Box>

        <LoginForm />
      </Box>
    </VStack>
  );
};
