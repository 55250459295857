import { Box, Button, Divider, HStack, Switch, VStack } from '@chakra-ui/react';
import React from 'react';
import {
  DMEnvelopeIcon,
  DMMachineIcon,
  DMMobilePhoneIcon,
  DMSensorsIcon,
  DMStatusAlarmIcon,
  DMStatusCriticalIcon,
  DMStatusMonitorIcon,
} from 'src/components/Icons';
import { useFormik } from 'formik';

export const NotificationsPreferences = () => {
  const formik = useFormik({
    initialValues: {
      receiveByEmail: true,
      receiveBySMS: false,
      receiveAllAlerts: false,
      receiveCriticalAlerts: true,
      receiveAlarmAlerts: true,
      receiveMonitorAlerts: true,
      receiveSensorFailureAlerts: false,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <VStack h="full">
          <Box w="full">
            <Box mb="2.5rem">
              <Box fontWeight="bold" fontSize="1rem" mb="1rem">
                Notifications settings
              </Box>

              <Box fontSize="0.875rem" fontWeight={500}>
                <HStack justifyContent="space-between" py="1.7rem">
                  <HStack>
                    <DMEnvelopeIcon fontSize="1rem" />
                    <Box>Receive by mail</Box>
                  </HStack>

                  <Box>
                    <Switch
                      id="receiveByEmail"
                      name="receiveByEmail"
                      onChange={formik.handleChange}
                      isChecked={formik.values.receiveByEmail}
                    />
                  </Box>
                </HStack>
                <Divider />

                <HStack justifyContent="space-between" py="1.7rem">
                  <HStack>
                    <DMMobilePhoneIcon fontSize="1rem" />
                    <Box>Receive by SMS</Box>
                  </HStack>

                  <Box>
                    <Switch
                      id="receiveBySMS"
                      name="receiveBySMS"
                      onChange={formik.handleChange}
                      isChecked={formik.values.receiveBySMS}
                    />
                  </Box>
                </HStack>
                <Divider />
              </Box>
            </Box>

            <Box mb="2.5rem">
              <Box fontWeight="bold" fontSize="1rem" mb="1rem">
                Machine status
              </Box>

              <Box fontSize="0.875rem" fontWeight={500}>
                <HStack justifyContent="space-between" py="1.7rem">
                  <HStack>
                    <DMMachineIcon fontSize="1rem" />
                    <Box>Receive all types</Box>
                  </HStack>
                  <Box>
                    <Switch
                      id="receiveAllAlerts"
                      name="receiveAllAlerts"
                      onChange={formik.handleChange}
                      isChecked={formik.values.receiveAllAlerts}
                    />
                  </Box>
                </HStack>
                <Divider />

                <HStack justifyContent="space-between" py="1.7rem">
                  <HStack>
                    <DMStatusCriticalIcon fontSize="1rem" color="health.critical" />
                    <Box>Receive critical alerts</Box>
                  </HStack>
                  <Box>
                    <Switch
                      id="receiveCriticalAlerts"
                      name="receiveCriticalAlerts"
                      onChange={formik.handleChange}
                      isChecked={formik.values.receiveCriticalAlerts}
                    />
                  </Box>
                </HStack>
                <Divider />

                <HStack justifyContent="space-between" py="1.7rem">
                  <HStack>
                    <DMStatusAlarmIcon fontSize="1rem" color="health.alarm" />
                    <Box>Receive alarm alerts</Box>
                  </HStack>

                  <Box>
                    <Switch
                      id="receiveAlarmAlerts"
                      name="receiveAlarmAlerts"
                      onChange={formik.handleChange}
                      isChecked={formik.values.receiveAlarmAlerts}
                    />
                  </Box>
                </HStack>
                <Divider />

                <HStack justifyContent="space-between" py="1.7rem">
                  <HStack>
                    <DMStatusMonitorIcon fontSize="1rem" color="health.monitor" />
                    <Box>Receive monitor alerts</Box>
                  </HStack>
                  <Box>
                    <Switch
                      id="receiveMonitorAlerts"
                      name="receiveMonitorAlerts"
                      onChange={formik.handleChange}
                      isChecked={formik.values.receiveMonitorAlerts}
                    />
                  </Box>
                </HStack>
                <Divider />

                <HStack justifyContent="space-between" py="1.7rem">
                  <HStack>
                    <DMSensorsIcon fontSize="1rem" />
                    <Box>Receive sensor failure alerts</Box>
                  </HStack>
                  <Box>
                    <Switch
                      id="receiveSensorFailureAlerts"
                      name="receiveSensorFailureAlerts"
                      onChange={formik.handleChange}
                      isChecked={formik.values.receiveSensorFailureAlerts}
                    />
                  </Box>
                </HStack>
                <Divider />
              </Box>
            </Box>
          </Box>

          <Box w="full">
            <Button type="submit" variant="data-mind" size="lg" borderRadius="full" w="full">
              Save changes
            </Button>
          </Box>
        </VStack>
      </form>
    </>
  );
};
