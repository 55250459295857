import React from 'react';
import { HStack, IconButton } from '@chakra-ui/react';
import { DMGridIcon, DMListIcon } from 'src/components/Icons';

export const LayoutToggleButtons = () => {
  return (
    <HStack spacing={2} pl={2}>
      <IconButton icon={<DMGridIcon fontSize="1.5rem" />} aria-label="Grid view" variant="naked" size="sm" isDisabled />
      <IconButton icon={<DMListIcon fontSize="1.5rem" />} aria-label="List view" variant="naked" size="sm" isDisabled />
    </HStack>
  );
};
