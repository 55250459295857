import React from 'react';
import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

interface FusionEvidenceModalProps {
  header?: string;
  image?: string;
}

export const FusionEvidenceModal = ({ header, image }: FusionEvidenceModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box onClick={onOpen} textTransform="uppercase" color="#7071f3" fontSize="0.75rem">
        View
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <ModalHeader fontWeight={400} fontSize="1rem">
            {header}
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody pb={6}>
            <Image src={image} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
