import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const DMSettingsIcon = createIcon({
  displayName: 'DMSettingsIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M19.43 12.98a7.793 7.793 0 0 0 .07-.98 7.793 7.793 0 0 0-.07-.98l2.11-1.65a.5.5 0 0 0 .12-.64l-2-3.46a.5.5 0 0 0-.44-.25.467.467 0 0 0-.17.03l-2.49 1a7.306 7.306 0 0 0-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4a.488.488 0 0 0-.49.42l-.38 2.65a7.683 7.683 0 0 0-1.69.98l-2.49-1a.566.566 0 0 0-.18-.03.5.5 0 0 0-.43.25l-2 3.46a.493.493 0 0 0 .12.64l2.11 1.65a7.931 7.931 0 0 0-.07.98 7.931 7.931 0 0 0 .07.98l-2.11 1.65a.5.5 0 0 0-.12.64l2 3.46a.5.5 0 0 0 .44.25.467.467 0 0 0 .17-.03l2.49-1a7.306 7.306 0 0 0 1.69.98l.38 2.65A.488.488 0 0 0 10 22h4a.488.488 0 0 0 .49-.42l.38-2.65a7.683 7.683 0 0 0 1.69-.98l2.49 1a.566.566 0 0 0 .18.03.5.5 0 0 0 .43-.25l2-3.46a.5.5 0 0 0-.12-.64zm-1.98-1.71a5.343 5.343 0 0 1 .05.73c0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68a5.857 5.857 0 0 1-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43a5.674 5.674 0 0 1-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68a5.857 5.857 0 0 1 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43a5.674 5.674 0 0 1 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2.006 2.006 0 0 1-2 2z"
    />,
  ],
});

export const DMSitesIcon = createIcon({
  displayName: 'DMSitesIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M543.541 243.307h-6.784l1 1v-6.828c0-.315.006-.632 0-.949v-.014c-.167.289-.331.578-.5.867.225-.173.449-.347.676-.52l1.621-1.246 1.96-1.509 1.689-1.3c.275-.211.554-.417.825-.634l.012-.01-1.509-.867v11.41c0 .526-.006 1.051 0 1.575v.022a1.044 1.044 0 0 0 .293.71 1.008 1.008 0 0 0 1.421 0 1.041 1.041 0 0 0 .293-.71V232.9c0-.526.006-1.051 0-1.575v-.025a1.013 1.013 0 0 0-.5-.867 1.005 1.005 0 0 0-1.011 0l-.626.482-1.513 1.164-1.878 1.445-1.7 1.3-.991.762-.074.056h-.006c-.235.181-.151.09-.281.259a1 1 0 0 0-.215.608V244.298a1.018 1.018 0 0 0 1 1h6.784a1.044 1.044 0 0 0 .71-.293 1.007 1.007 0 0 0 0-1.421 1.074 1.074 0 0 0-.696-.277z"
      transform="translate(-523.451 -223.59)"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M203.873 243.405h-6.813l1 1v-6.828c0-.315.006-.632 0-.949v-.014c-.167.289-.331.578-.5.867l.68-.52q.813-.62 1.623-1.242.984-.755 1.97-1.509l1.7-1.3c.277-.211.558-.419.831-.636a.043.043 0 0 1 .012-.008l-1.509-.867v11.41c0 .526-.006 1.051 0 1.575v.022a1.044 1.044 0 0 0 .293.71 1.008 1.008 0 0 0 1.421 0 1.041 1.041 0 0 0 .293-.71V233c0-.526.006-1.051 0-1.575v-.025a1.013 1.013 0 0 0-.5-.867 1.005 1.005 0 0 0-1.011 0l-.626.48-1.519 1.164-1.88 1.439-1.705 1.306-1 .764-.076.058h-.006l-.1.078a.955.955 0 0 0-.359.522 1.456 1.456 0 0 0-.036.281v7.777a1.018 1.018 0 0 0 1 1h6.814a1.044 1.044 0 0 0 .71-.293 1.008 1.008 0 0 0 0-1.421 1.05 1.05 0 0 0-.707-.283z"
      transform="translate(-190.567 -223.688)"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M50.455 62.41a1.008 1.008 0 0 0 0 1.421 1.041 1.041 0 0 0 .71.293h2.927a1.018 1.018 0 0 0 1-1V46.808a1.368 1.368 0 0 0-.417-1.045 1.409 1.409 0 0 0-.913-.363h-2.2a1.388 1.388 0 0 0-1.031.407 1.421 1.421 0 0 0-.371 1.009v16.307a1.044 1.044 0 0 0 .293.71 1.008 1.008 0 0 0 1.421 0c.182-.199-1.236-1.603-1.419-1.423z"
      transform="translate(-47.601 -42.4)"
    />,
  ],
});

export const DMLogoutIcon = createIcon({
  displayName: 'DMLogoutIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M5 5h6a1 1 0 0 0 1-1 1 1 0 0 0-1-1H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h6a1 1 0 0 0 1-1 1 1 0 0 0-1-1H5z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="m20.65 11.65-2.79-2.79a.5.5 0 0 0-.86.35V11h-7a1 1 0 0 0-1 1 1 1 0 0 0 1 1h7v1.79a.5.5 0 0 0 .85.35l2.79-2.79a.492.492 0 0 0 .01-.7z"
    />,
  ],
});

export const DMPredictIcon = createIcon({
  displayName: 'DMPredictIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M21 8a2 2 0 0 0-1.93 2.51l-3.55 3.56a2.037 2.037 0 0 0-1.04 0l-2.55-2.55a2 2 0 1 0-3.86 0l-4.56 4.55a2 2 0 1 0 1.42 1.42l4.55-4.56a2.037 2.037 0 0 0 1.04 0l2.55 2.55a2 2 0 1 0 3.86 0l3.56-3.55A2 2 0 1 0 21 8z"
    />,
    <path key="1" fill="currentColor" d="m15 9 .94-2.07L18 6l-2.06-.93L15 3l-.92 2.07L12 6l2.08.93z" />,
    <path key="2" fill="currentColor" d="M3.5 11 4 9l2-.5L4 8l-.5-2L3 8l-2 .5L3 9z" />,
  ],
});

export const DMNotificationsIcon = createIcon({
  displayName: 'DMNotificationsIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M12 22a2.006 2.006 0 0 0 2-2h-4a2.006 2.006 0 0 0 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4a1.5 1.5 0 0 0-3 0v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5z"
    />,
  ],
});

export const DMInfoCircleIcon = createIcon({
  displayName: 'DMInfoCircleIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M9.167 14.083h1.666V9H9.167zM10 7.6a.854.854 0 1 0-.6-.25.823.823 0 0 0 .6.25zm0 10.563a8 8 0 0 1-3.177-.636 8.15 8.15 0 0 1-4.354-4.354A8 8 0 0 1 1.833 10a7.981 7.981 0 0 1 .636-3.187 8.177 8.177 0 0 1 4.354-4.344A8 8 0 0 1 10 1.833a7.988 7.988 0 0 1 3.188.636 8.179 8.179 0 0 1 4.343 4.354A8 8 0 0 1 18.167 10a8 8 0 0 1-.636 3.177 8.15 8.15 0 0 1-4.354 4.354 8 8 0 0 1-3.177.636zm0-1.729a6.206 6.206 0 0 0 4.562-1.876A6.206 6.206 0 0 0 16.438 10a6.206 6.206 0 0 0-1.876-4.562A6.206 6.206 0 0 0 10 3.562a6.206 6.206 0 0 0-4.562 1.876A6.206 6.206 0 0 0 3.562 10a6.206 6.206 0 0 0 1.876 4.562A6.206 6.206 0 0 0 10 16.438zM10 10z"
      transform="translate(2 2)"
    />,
  ],
});

export const DMFileIcon = createIcon({
  displayName: 'DMFileIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M5.562 18.167a1.708 1.708 0 0 1-1.729-1.729V3.562a1.708 1.708 0 0 1 1.729-1.729h6.5l4.1 4.105v10.5a1.708 1.708 0 0 1-1.729 1.729zm5.376-11.1v-3.5H5.562v12.871h8.876V7.062zm-5.376-3.5z"
      transform="translate(2 2)"
    />,
  ],
});

export const DMSensorsIcon = createIcon({
  displayName: 'DMSensorsIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M16 20.989a2.407 2.407 0 1 1 1.7-.7 2.311 2.311 0 0 1-1.7.7zm-9.09 5.43a12.563 12.563 0 0 1-2.13-3.585 11.941 11.941 0 0 1 0-8.49 12.563 12.563 0 0 1 2.13-3.585.786.786 0 0 1 .615-.27.9.9 0 0 1 .9.885.986.986 0 0 1-.225.675 10.994 10.994 0 0 0-1.755 3 9.636 9.636 0 0 0-.645 3.54 9.84 9.84 0 0 0 .63 3.54 10.327 10.327 0 0 0 1.77 3 .986.986 0 0 1 .225.675.936.936 0 0 1-.285.645.835.835 0 0 1-1.23-.03zM10.3 23a7.451 7.451 0 0 1-1.1-2.025 7.225 7.225 0 0 1 0-4.77 7.451 7.451 0 0 1 1.1-2.025.753.753 0 0 1 .615-.3.88.88 0 0 1 .645.27.945.945 0 0 1 .27.615 1.03 1.03 0 0 1-.21.7 5.422 5.422 0 0 0-.75 1.455 5.142 5.142 0 0 0-.27 1.665 5.418 5.418 0 0 0 .255 1.665 5.109 5.109 0 0 0 .735 1.455 1.1 1.1 0 0 1 .225.7.936.936 0 0 1-.285.645.812.812 0 0 1-.63.255.783.783 0 0 1-.6-.305zm10.17.06a1.027 1.027 0 0 1-.285-.63.993.993 0 0 1 .195-.72 5.422 5.422 0 0 0 .75-1.455 5.142 5.142 0 0 0 .27-1.665 5.418 5.418 0 0 0-.255-1.665 5.109 5.109 0 0 0-.735-1.455 1.1 1.1 0 0 1-.225-.7.936.936 0 0 1 .285-.645.812.812 0 0 1 .63-.255.783.783 0 0 1 .6.315 7.451 7.451 0 0 1 1.1 2.015 6.987 6.987 0 0 1 .4 2.385 6.861 6.861 0 0 1-.4 2.37A8.013 8.013 0 0 1 21.7 23a.783.783 0 0 1-.6.315.812.812 0 0 1-.63-.256zm3.39 3.39a.962.962 0 0 1-.27-.63.95.95 0 0 1 .21-.69 10.994 10.994 0 0 0 1.755-3 9.636 9.636 0 0 0 .645-3.54 9.84 9.84 0 0 0-.63-3.54 10.327 10.327 0 0 0-1.77-3 .986.986 0 0 1-.225-.675.936.936 0 0 1 .285-.645.835.835 0 0 1 1.23.03 12.563 12.563 0 0 1 2.13 3.585 11.941 11.941 0 0 1 0 8.49 12.562 12.562 0 0 1-2.13 3.585.835.835 0 0 1-1.23.03z"
      transform="translate(-4 -6.474)"
    />,
  ],
});

export const DMMachineIcon = createIcon({
  displayName: 'DMMachineIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M41.2 100.956H30.016a1.74 1.74 0 0 0 0 3.481H41.2a1.74 1.74 0 1 0 0-3.481zm-11.02 2.954a1.209 1.209 0 1 1 1.209-1.209 1.209 1.209 0 0 1-1.207 1.209zm10.984 0a1.209 1.209 0 1 1 1.209-1.209 1.209 1.209 0 0 1-1.207 1.209z"
      transform="translate(-27.61 -91.684)"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M44.34 72.243h1.275a.683.683 0 0 0 .683-.683v-1.275a.683.683 0 0 0-.683-.683H44.34a.683.683 0 0 0-.683.683v1.275a.683.683 0 0 0 .683.683z"
      transform="translate(-41.537 -63.296)"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M77.311 72.243h1.275a.683.683 0 0 0 .683-.683v-1.275a.683.683 0 0 0-.683-.683h-1.275a.683.683 0 0 0-.683.683v1.275a.683.683 0 0 0 .683.683z"
      transform="translate(-71.389 -63.296)"
    />,
    <path
      key="3"
      fill="currentColor"
      d="M77.311 40.807h1.275a.683.683 0 0 0 .683-.683v-1.275a.683.683 0 0 0-.683-.683h-1.275a.683.683 0 0 0-.683.683v1.275a.683.683 0 0 0 .683.683z"
      transform="translate(-71.389 -34.833)"
    />,
    <path
      key="4"
      fill="currentColor"
      d="M110.28 72.243h1.275a.683.683 0 0 0 .683-.683v-1.275a.683.683 0 0 0-.683-.683h-1.275a.683.683 0 0 0-.683.683v1.275a.683.683 0 0 0 .683.683z"
      transform="translate(-101.24 -63.296)"
    />,
    <path
      key="5"
      fill="currentColor"
      d="M110.28 40.807h1.275a.683.683 0 0 0 .683-.683v-1.275a.683.683 0 0 0-.683-.683h-1.275a.683.683 0 0 0-.683.683v1.275a.683.683 0 0 0 .683.683z"
      transform="translate(-101.24 -34.833)"
    />,
    <path
      key="6"
      fill="currentColor"
      d="M143.25 72.243h1.275a.683.683 0 0 0 .683-.683v-1.275a.683.683 0 0 0-.683-.683h-1.275a.683.683 0 0 0-.683.683v1.275a.683.683 0 0 0 .683.683z"
      transform="translate(-131.092 -63.296)"
    />,
    <path
      key="7"
      fill="currentColor"
      d="M143.25 40.807h1.275a.683.683 0 0 0 .683-.683v-1.275a.683.683 0 0 0-.683-.683h-1.275a.683.683 0 0 0-.683.683v1.275a.683.683 0 0 0 .683.683z"
      transform="translate(-131.092 -34.833)"
    />,
  ],
});

export const DMStatusHealthyIcon = createIcon({
  displayName: 'DMStatusHealthyIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="m10.537 13.375-1.852-1.852a.69.69 0 0 0-.966 0 .7.7 0 0 0-.217.512.668.668 0 0 0 .2.493l2.365 2.365a.693.693 0 0 0 .926 0l4.69-4.69a.656.656 0 0 0 .2-.483.69.69 0 0 0-.217-.5.668.668 0 0 0-.493-.2.7.7 0 0 0-.512.217zM11.7 19.7a8.149 8.149 0 0 1-3.163-.611 7.672 7.672 0 0 1-4.227-4.227A8.149 8.149 0 0 1 3.7 11.7a8.1 8.1 0 0 1 .611-3.143A7.777 7.777 0 0 1 6 6.015a7.907 7.907 0 0 1 2.537-1.694A8.036 8.036 0 0 1 11.7 3.7a7.986 7.986 0 0 1 3.143.621A7.914 7.914 0 0 1 19.7 11.7a8.036 8.036 0 0 1-.621 3.163 7.907 7.907 0 0 1-1.694 2.537 7.777 7.777 0 0 1-2.542 1.685 8.1 8.1 0 0 1-3.143.615z"
      transform="translate(-3.7 -3.7)"
    />,
  ],
});

export const DMStatusCriticalIcon = createIcon({
  displayName: 'DMStatusCriticalIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M10.659 20.7a1.449 1.449 0 0 1-.543-.1 1.365 1.365 0 0 1-.461-.307L6.11 16.745a1.365 1.365 0 0 1-.307-.461 1.448 1.448 0 0 1-.1-.543v-5.082a1.448 1.448 0 0 1 .1-.543 1.365 1.365 0 0 1 .307-.461L9.655 6.11a1.365 1.365 0 0 1 .461-.307 1.448 1.448 0 0 1 .543-.1h5.082a1.448 1.448 0 0 1 .543.1 1.365 1.365 0 0 1 .461.307l3.545 3.545a1.365 1.365 0 0 1 .307.461 1.449 1.449 0 0 1 .1.543v5.082a1.449 1.449 0 0 1-.1.543 1.365 1.365 0 0 1-.307.461l-3.545 3.545a1.365 1.365 0 0 1-.461.307 1.449 1.449 0 0 1-.543.1zm2.541-6.6 2.07 2.07a.6.6 0 0 0 .441.184.708.708 0 0 0 .645-.645.637.637 0 0 0-.2-.461L14.1 13.2l2.07-2.07a.6.6 0 0 0 .184-.441.708.708 0 0 0-.645-.645.637.637 0 0 0-.461.2L13.2 12.3l-2.07-2.07a.6.6 0 0 0-.441-.184.708.708 0 0 0-.645.645.637.637 0 0 0 .2.461L12.3 13.2l-2.07 2.07a.6.6 0 0 0-.184.441.708.708 0 0 0 .645.645.637.637 0 0 0 .461-.2z"
      transform="translate(-5.7 -5.7)"
    />,
  ],
});

export const DMStatusAlarmIcon = createIcon({
  displayName: 'DMStatusAlarmIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M13.994 12.644a.572.572 0 0 0 .59-.59V9.121a.591.591 0 1 0-1.181 0v2.932a.572.572 0 0 0 .59.59zm0 2.125a.672.672 0 1 0-.472-.2.644.644 0 0 0 .472.2zm-5.825 2.5a.669.669 0 1 1 0-1.338h1.023v-5.885a4.967 4.967 0 0 1 .994-3.021 4.294 4.294 0 0 1 2.627-1.7v-.553a1.053 1.053 0 0 1 .344-.8 1.24 1.24 0 0 1 1.673 0 1.053 1.053 0 0 1 .344.8v.551a4.334 4.334 0 0 1 2.637 1.7 4.935 4.935 0 0 1 1 3.021v5.886h1a.668.668 0 0 1 .492.2.644.644 0 0 1 .2.472.635.635 0 0 1-.2.482.686.686 0 0 1-.492.187zm5.825 2.381a1.565 1.565 0 0 1-1.132-.472 1.539 1.539 0 0 1-.482-1.141h3.228a1.613 1.613 0 0 1-1.614 1.614z"
      transform="translate(-7.5 -3.65)"
    />,
  ],
});

export const DMStatusMonitorIcon = createIcon({
  displayName: 'DMStatusMonitorIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M11.089 6.794v3.744a.581.581 0 0 0 .177.443.608.608 0 0 0 .433.167.576.576 0 0 0 .611-.611V6.794a.606.606 0 0 0-.611-.611.581.581 0 0 0-.443.177.608.608 0 0 0-.167.434zm.611 7.133a.7.7 0 0 0 .5-.2.652.652 0 0 0 .207-.493.675.675 0 0 0-.207-.512.739.739 0 0 0-1 0 .652.652 0 0 0-.207.493.675.675 0 0 0 .207.512.7.7 0 0 0 .5.2zm-8 4.158V5.04a1.285 1.285 0 0 1 .4-.94 1.285 1.285 0 0 1 .936-.4H18.36a1.285 1.285 0 0 1 .936.4 1.285 1.285 0 0 1 .4.936v10.171a1.3 1.3 0 0 1-.4.926 1.27 1.27 0 0 1-.936.414H6.853l-2.01 2.01a.623.623 0 0 1-.729.148.616.616 0 0 1-.414-.621z"
      transform="translate(-3.7 -3.7)"
    />,
  ],
});

export const DMStatusNotAvailableIcon = createIcon({
  displayName: 'DMStatusNotAvailableIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M8 16a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8zm0-5.609a.782.782 0 0 0-.568.242 1.01 1.01 0 0 0-.1.117.8.8 0 0 0-.07.148.376.376 0 0 0-.047.139.768.768 0 0 0 0 .164.616.616 0 0 0 .064.3.712.712 0 0 0 .434.434.787.787 0 0 0 .3.064.758.758 0 0 0 .3-.064.722.722 0 0 0 .434-.434.766.766 0 0 0 .049-.3.826.826 0 0 0-.23-.568.8.8 0 0 0-.566-.242zM7.988 5.6H8a.8.8 0 1 1 0 1.6.8.8 0 0 0-.8.8v.8a.8.8 0 1 0 1.6 0v-.144a2.4 2.4 0 1 0-2.878-3.463.81.81 0 0 0-.023.824.779.779 0 0 0 .691.41h.018A.811.811 0 0 0 7.3 6a.8.8 0 0 1 .688-.4z"
    />,
  ],
});

export const DMExportIcon = createIcon({
  displayName: 'DMExportIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="m7.034 6.285 1.718-1.726v7.194a.75.75 0 0 0 1.5 0V4.559l1.718 1.726a.753.753 0 1 0 1.065-1.065l-3-3a.776.776 0 0 0-1.065 0l-3 3a.753.753 0 0 0 1.064 1.065zM16.254 11a.75.75 0 0 0-.75.75v3a.75.75 0 0 1-.75.75H4.251a.75.75 0 0 1-.75-.75v-3a.75.75 0 1 0-1.5 0v3A2.251 2.251 0 0 0 4.251 17h10.5A2.251 2.251 0 0 0 17 14.754v-3a.75.75 0 0 0-.746-.754z"
      transform="translate(2.5 2.494)"
    />,
  ],
});

export const DMLockIcon = createIcon({
  displayName: 'DMLockIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M13.745 7.247v-1.5a3.748 3.748 0 1 0-7.5 0v1.5A2.249 2.249 0 0 0 4 9.5v5.247a2.249 2.249 0 0 0 2.249 2.249h7.5a2.249 2.249 0 0 0 2.249-2.249V9.5a2.249 2.249 0 0 0-2.253-2.253zm-6-1.5a2.249 2.249 0 1 1 4.5 0v1.5h-4.5zm6.747 9a.75.75 0 0 1-.75.75h-7.5a.75.75 0 0 1-.75-.75V9.5a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 .75.75z"
      transform="translate(2 3)"
    />,
  ],
});

export const DMUserIcon = createIcon({
  displayName: 'DMUserIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M9.213 2a7.207 7.207 0 0 0-5.3 12.08 7.207 7.207 0 0 0 10.595 0A7.207 7.207 0 0 0 9.213 2zm0 12.973a5.766 5.766 0 0 1-4-1.622 4.324 4.324 0 0 1 8 0 5.766 5.766 0 0 1-4 1.622zM7.771 7.766a1.441 1.441 0 1 1 1.442 1.441 1.441 1.441 0 0 1-1.442-1.441zm6.422 4.324a5.766 5.766 0 0 0-2.818-2.436 2.883 2.883 0 1 0-4.324 0 5.766 5.766 0 0 0-2.819 2.436 5.708 5.708 0 0 1-.786-2.883 5.766 5.766 0 1 1 11.532 0 5.708 5.708 0 0 1-.785 2.883z"
      transform="translate(2.781 2.8)"
    />,
  ],
});

export const DMArrowRightIcon = createIcon({
  displayName: 'DMArrowRightIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M11.663.331a1.138 1.138 0 0 0-1.605 0l-4.086 4.03-4.03-4.03A1.139 1.139 0 1 0 .337 1.947l4.826 4.827a1.138 1.138 0 0 0 1.616 0l4.884-4.827a1.138 1.138 0 0 0 0-1.616z"
      transform="rotate(-90 13.313 4.687)"
    />,
  ],
});

export const DMBackArrowIcon = createIcon({
  displayName: 'DMBackArrowIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M16.991 11H9.407l3.3-3.287a1 1 0 1 0-1.421-1.423l-5 5a1.033 1.033 0 0 0 0 1.419l5 5a1 1 0 1 0 1.414-1.427l-3.3-3.287h7.584a1 1 0 0 0 0-2z"
      transform="translate(-.005 .004)"
    />,
  ],
});

export const DMThumbDownIcon = createIcon({
  displayName: 'DMThumbDownIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M15.6 2H5.416a2.4 2.4 0 0 0-2.36 1.968l-1.016 5.6A2.4 2.4 0 0 0 4.4 12.4h3.648L7.6 13.545A3.3 3.3 0 0 0 10.713 18a.8.8 0 0 0 .728-.472l2.28-5.128H15.6A2.4 2.4 0 0 0 18 10V4.4A2.4 2.4 0 0 0 15.6 2zm-3.2 9.432-2.176 4.9a1.7 1.7 0 0 1-1.1-2.224l.424-1.144a1.6 1.6 0 0 0-1.5-2.16H4.4a.816.816 0 0 1-.8-.944l1.016-5.6a.8.8 0 0 1 .8-.656H12.4zm4-1.432a.8.8 0 0 1-.8.8H14V3.6h1.6a.8.8 0 0 1 .8.8z"
      transform="translate(1.998 2)"
    />,
  ],
});

export const DMThumbUpIcon = createIcon({
  displayName: 'DMThumbUpIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M17.441 8.465A2.4 2.4 0 0 0 15.6 7.6h-3.647l.447-1.144A3.3 3.3 0 0 0 9.289 2a.8.8 0 0 0-.728.472L6.28 7.6H4.4A2.4 2.4 0 0 0 2 10v5.6A2.4 2.4 0 0 0 4.4 18h10.185a2.4 2.4 0 0 0 2.36-1.968l1.016-5.6a2.4 2.4 0 0 0-.52-1.967zM6 16.4H4.4a.8.8 0 0 1-.8-.8V10a.8.8 0 0 1 .8-.8H6zm10.4-6.257-1.016 5.6a.8.8 0 0 1-.8.656H7.6v-7.83l2.176-4.9a1.688 1.688 0 0 1 1.1 2.224l-.419 1.147a1.6 1.6 0 0 0 1.5 2.16H15.6a.816.816 0 0 1 .8.944z"
      transform="translate(2 2)"
    />,
  ],
});

export const DMChartIcon = createIcon({
  displayName: 'DMChartIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M5.9 13.2A1.2 1.2 0 0 0 7.1 12a.616.616 0 0 0 0-.12l2.232-2.232H9.7l1.288 1.288V11a1.2 1.2 0 1 0 2.4 0v-.064L16.3 8a1.2 1.2 0 1 0-1.2-1.2.616.616 0 0 0 0 .12l-2.888 2.888h-.128L10.7 8.4a1.2 1.2 0 0 0-2.4 0l-2.4 2.4a1.2 1.2 0 0 0 0 2.4zm10.8 3.2H3.1V2.8a.8.8 0 1 0-1.6 0v14.4a.8.8 0 0 0 .8.8h14.4a.8.8 0 1 0 0-1.6z"
      transform="translate(2.5 2)"
    />,
  ],
});

export const DMZoomInIcon = createIcon({
  displayName: 'DMZoomInIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M11.746 8H9.5V5.745a.75.75 0 1 0-1.5 0V8H5.745a.75.75 0 0 0 0 1.5H8v2.25a.75.75 0 1 0 1.5 0V9.5h2.25a.75.75 0 1 0 0-1.5zm5.033 7.719L14 12.954A6.751 6.751 0 1 0 12.954 14l2.76 2.76a.745.745 0 1 0 1.065-1.043zM8.746 14A5.251 5.251 0 1 1 14 8.746 5.251 5.251 0 0 1 8.746 14z"
      transform="translate(2.021 3.021)"
    />,
  ],
});

export const DMPlusCircleIcon = createIcon({
  displayName: 'DMPlusCircleIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M0 7.5A7.5 7.5 0 1 1 7.5 15 7.5 7.5 0 0 1 0 7.5zm1.5 0a6 6 0 1 0 6-6 6 6 0 0 0-6 6zm5.25 2.248v-1.5h-1.5a.751.751 0 0 1 0-1.5h1.5V5.25a.751.751 0 0 1 1.5 0v1.5h1.5a.751.751 0 0 1 0 1.5h-1.5v1.5a.751.751 0 1 1-1.5 0z"
      transform="translate(4.499 4.501)"
    />,
  ],
});

export const DMMapIcon = createIcon({
  displayName: 'DMMapIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="m16.49 4.537-4.5-1.5h-.052a.525.525 0 0 0-.105 0h-.323L7.25 4.5 2.99 3.037A.756.756 0 0 0 2 3.75v10.5a.75.75 0 0 0 .51.713l4.5 1.5a.75.75 0 0 0 .465 0l4.275-1.425 4.26 1.462a.892.892 0 0 0 .24 0 .7.7 0 0 0 .435-.142.75.75 0 0 0 .315-.608V5.25a.75.75 0 0 0-.51-.713zM6.5 14.707l-3-1V4.792l3 1zm4.5-1-3 1V5.79l3-1zm4.5 1-3-1V4.792l3 1z"
      transform="translate(2.5 2.249)"
    />,
  ],
});

export const DMListIcon = createIcon({
  displayName: 'DMListIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M17.4 10.8H2.8a.8.8 0 0 0-.8.8v5.6a.8.8 0 0 0 .8.8h14.6a.8.8 0 0 0 .8-.8v-5.6a.8.8 0 0 0-.8-.8zm-.8 5.6h-13v-4h13zM17.4 2H2.8a.8.8 0 0 0-.8.8v5.6a.8.8 0 0 0 .8.8h14.6a.8.8 0 0 0 .8-.8V2.8a.8.8 0 0 0-.8-.8zm-.8 5.6h-13v-4h13z"
      transform="translate(2 2)"
    />,
  ],
});

export const DMGridIcon = createIcon({
  displayName: 'DMGridIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M8.4 10.8H2.8a.8.8 0 0 0-.8.8v5.6a.8.8 0 0 0 .8.8h5.6a.8.8 0 0 0 .8-.8v-5.6a.8.8 0 0 0-.8-.8zm-.8 5.6h-4v-4h4zM17.2 2h-5.6a.8.8 0 0 0-.8.8v5.6a.8.8 0 0 0 .8.8h5.6a.8.8 0 0 0 .8-.8V2.8a.8.8 0 0 0-.8-.8zm-.8 5.6h-4v-4h4zm.8 3.2h-5.6a.8.8 0 0 0-.8.8v5.6a.8.8 0 0 0 .8.8h5.6a.8.8 0 0 0 .8-.8v-5.6a.8.8 0 0 0-.8-.8zm-.8 5.6h-4v-4h4zM8.4 2H2.8a.8.8 0 0 0-.8.8v5.6a.8.8 0 0 0 .8.8h5.6a.8.8 0 0 0 .8-.8V2.8a.8.8 0 0 0-.8-.8zm-.8 5.6h-4v-4h4z"
      transform="translate(2 2)"
    />,
  ],
});

export const DMClockRunningIcon = createIcon({
  displayName: 'DMClockRunningIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M1.172 15.626a4 4 0 0 1 0-5.656 4.023 4.023 0 0 1 .945-.7 6.355 6.355 0 0 1-.111-1.57 7.682 7.682 0 0 1 7.8-7.7 7.488 7.488 0 0 1 5.5 2.249 7.566 7.566 0 0 1 2.3 5.552 7.136 7.136 0 0 1-2.349 5.5h.05a6.8 6.8 0 0 1-5.451 2.3h-.1a6.626 6.626 0 0 1-2.2-.3.637.637 0 0 1-.308-.165 4.051 4.051 0 0 1-.418.491 4 4 0 0 1-5.657 0zm4.145-.944a.41.41 0 0 0 .158.1.36.36 0 0 0 .125.018.4.4 0 0 0 .283-.117A.411.411 0 0 0 6 14.4a.4.4 0 0 0-.118-.281L4.566 12.8l1.317-1.317A.4.4 0 0 0 6 11.2a.406.406 0 0 0-.118-.283.4.4 0 0 0-.566 0L4 12.234l-1.317-1.317a.4.4 0 0 0-.564 0A.4.4 0 0 0 2 11.2a.393.393 0 0 0 .117.283L3.435 12.8l-1.317 1.317A.388.388 0 0 0 2 14.4a.4.4 0 0 0 .117.283.4.4 0 0 0 .564 0L4 13.364zm2.69-.932a5.09 5.09 0 0 0 1.749.25.265.265 0 0 1 .05 0 5.245 5.245 0 0 0 4.3-1.8c.017 0 .033-.015.05-.048A5.627 5.627 0 0 0 16.008 7.8a6 6 0 0 0-1.852-4.4 5.918 5.918 0 0 0-4.35-1.8 6.148 6.148 0 0 0-6.2 6.2.188.188 0 0 1 0 .048 4.9 4.9 0 0 0 .044.965C3.766 8.8 3.882 8.8 4 8.8a4 4 0 0 1 3.892 4.936.785.785 0 0 1 .115.014zm3.465-4.055L9.391 8.5l-.072-.071-.129-.1a.7.7 0 0 1-.078-.128.757.757 0 0 1-.065-.136.521.521 0 0 1-.04-.16V4.3a.8.8 0 1 1 1.6 0v3.036l1.678.967h-.015a.8.8 0 0 1-.4 1.5h-.01a.77.77 0 0 1-.388-.108z"
      transform="translate(3.198 3.6)"
    />,
  ],
});

export const DMEnergyIcon = createIcon({
  displayName: 'DMEnergyIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M16.7 7.279A.8.8 0 0 0 16 6.8h-3.663l1.016-3.791A.8.8 0 0 0 12.585 2h-5.6a.8.8 0 0 0-.8.592l-2.144 8a.8.8 0 0 0 .778 1.008h3.1l-1.453 5.389a.8.8 0 0 0 1.368.744l8.718-9.6a.8.8 0 0 0 .148-.854zm-7.83 6.943.856-3.2a.8.8 0 0 0-.768-1.008H5.883L7.594 3.6h3.943l-1.015 3.791a.8.8 0 0 0 .8 1.008h2.855z"
      transform="translate(1.985 2)"
    />,
  ],
});

export const DMMobilePhoneIcon = createIcon({
  displayName: 'DMMobilePhoneIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="m10.782 12.717-.112-.09a.57.57 0 0 0-.135-.068L10.4 12.5a.75.75 0 0 0-.683.2.863.863 0 0 0-.157.247.75.75 0 0 0 .975.983 1.1 1.1 0 0 0 .248-.165.742.742 0 0 0 0-1.05zM13.25 2h-6A2.25 2.25 0 0 0 5 4.25v10.5A2.25 2.25 0 0 0 7.25 17h6a2.25 2.25 0 0 0 2.25-2.25V4.25A2.25 2.25 0 0 0 13.25 2zM14 14.75a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75V4.25a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75z"
      transform="translate(1.75 2.5)"
    />,
  ],
});

export const DMEnvelopeIcon = createIcon({
  displayName: 'DMEnvelopeIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M14.75 4H4.25A2.25 2.25 0 0 0 2 6.25v7.5A2.25 2.25 0 0 0 4.25 16h10.5A2.25 2.25 0 0 0 17 13.75v-7.5A2.25 2.25 0 0 0 14.75 4zm-.307 1.5-4.41 4.41a.75.75 0 0 1-1.065 0L4.558 5.5zm1.057 8.25a.75.75 0 0 1-.75.75H4.25a.75.75 0 0 1-.75-.75V6.558l4.41 4.41a2.25 2.25 0 0 0 3.18 0l4.41-4.41z"
      transform="translate(2.5 2)"
    />,
  ],
});

export const DMTuneIcon = createIcon({
  displayName: 'DMTuneIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M3 14.667a.78.78 0 0 0 .778.778h3.889v-1.556H3.778a.78.78 0 0 0-.778.778zm0-9.334a.78.78 0 0 0 .778.778h7V4.556h-7A.78.78 0 0 0 3 5.333zm7.778 10.889v-.778h5.444a.778.778 0 0 0 0-1.556h-5.444v-.778a.778.778 0 0 0-1.556 0v3.111a.778.778 0 0 0 1.556 0zM6.111 8.444v.778H3.778a.778.778 0 0 0 0 1.556h2.333v.778a.778.778 0 1 0 1.556 0V8.444a.778.778 0 1 0-1.556 0zM17 10a.78.78 0 0 0-.778-.778h-7v1.556h7A.78.78 0 0 0 17 10zm-3.889-2.333a.78.78 0 0 0 .778-.778v-.778h2.333a.778.778 0 0 0 0-1.556h-2.333v-.777a.778.778 0 0 0-1.556 0v3.111a.78.78 0 0 0 .778.778z"
      transform="translate(2 2)"
    />,
  ],
});

export const DMUsersIcon = createIcon({
  displayName: 'DMUsersIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M9.974 10.425a3.907 3.907 0 0 0 1.35-2.954 3.971 3.971 0 0 0-7.941 0 3.907 3.907 0 0 0 1.35 2.954A6.353 6.353 0 0 0 1 16.206a.794.794 0 1 0 1.588 0 4.765 4.765 0 0 1 9.529 0 .794.794 0 1 0 1.588 0 6.353 6.353 0 0 0-3.732-5.781zm-2.621-.572a2.382 2.382 0 1 1 2.382-2.382 2.382 2.382 0 0 1-2.382 2.382zm7.735.254a3.971 3.971 0 0 0-2.97-6.607.794.794 0 0 0 0 1.588 2.378 2.378 0 0 1 1.191 4.439.793.793 0 0 0-.04 1.35l.31.206.1.056a5.559 5.559 0 0 1 3.176 5.066.794.794 0 0 0 1.588 0 7.147 7.147 0 0 0-3.355-6.098z"
      transform="translate(2.225 1.75)"
    />,
  ],
});

export const DMImageUploadIcon = createIcon({
  displayName: 'DMImageUploadIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M14.14 9.57a.714.714 0 0 0-.714.714v.271L12.369 9.5a1.992 1.992 0 0 0-2.806 0l-.5.5-1.772-1.772a2.035 2.035 0 0 0-2.806 0L3.428 9.285v-4a.714.714 0 0 1 .714-.714h5a.714.714 0 1 0 0-1.428h-5A2.142 2.142 0 0 0 2 5.286v8.569A2.142 2.142 0 0 0 4.142 16h8.569a2.142 2.142 0 0 0 2.142-2.142v-3.57a.714.714 0 0 0-.713-.718zm-10 5a.714.714 0 0 1-.714-.714v-2.55L5.5 9.235a.564.564 0 0 1 .778 0L8.541 11.5l3.071 3.071zm9.283-.714a.636.636 0 0 1-.129.378L10.076 11l.5-.5a.55.55 0 0 1 .785 0l2.064 2.078zm3.363-10.5-2.139-2.147a.738.738 0 0 0-1.014 0L11.49 3.351a.717.717 0 0 0 1.01 1.014l.921-.928v3.991a.714.714 0 1 0 1.428 0V3.436l.921.928a.717.717 0 1 0 1.014-1.014z"
      transform="translate(2.5 3.494)"
    />,
  ],
});

export const DMEmailConfirmedIcon = createIcon({
  displayName: 'DMEmailConfirmedIcon',
  viewBox: '0 0 64 64',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M33.748 15.691a2.222 2.222 0 0 0 3.155 0L45.79 6.8a2.231 2.231 0 1 0-3.155-3.155l-7.309 7.332-2.866-2.884a2.231 2.231 0 0 0-3.16 3.155zm10.464-1.577a2.222 2.222 0 0 0-2.222 2.222v20a2.222 2.222 0 0 1-2.222 2.222H8.665a2.222 2.222 0 0 1-2.222-2.222V15.024L19.507 28.11a6.665 6.665 0 0 0 4.688 1.933 6.843 6.843 0 0 0 4.8-2l3.821-3.821a2.231 2.231 0 1 0-3.155-3.155l-3.888 3.888a2.222 2.222 0 0 1-3.11 0L9.576 11.892H22a2.222 2.222 0 1 0 0-4.443H8.665A6.665 6.665 0 0 0 2 14.114v22.217A6.665 6.665 0 0 0 8.665 43h31.1a6.665 6.665 0 0 0 6.665-6.665v-20a2.222 2.222 0 0 0-2.217-2.221z"
      transform="translate(8.001 9.004)"
    />,
  ],
});

export const DMEmailLockedIcon = createIcon({
  displayName: 'DMEmailLockedIcon',
  viewBox: '0 0 64 64',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M41.5 12.06V9.576a6.316 6.316 0 0 0-12.632 0v2.484a6.316 6.316 0 0 0-4.21 5.94v4.211a6.316 6.316 0 0 0 6.316 6.316h8.421a6.316 6.316 0 0 0 6.316-6.316V18a6.316 6.316 0 0 0-4.211-5.94zm-8.421-2.484a2.105 2.105 0 0 1 4.211 0v2.105h-4.211zM41.5 22.207a2.105 2.105 0 0 1-2.105 2.105h-8.421a2.105 2.105 0 0 1-2.105-2.105V18a2.105 2.105 0 0 1 2.105-2.105h8.421A2.105 2.105 0 0 1 41.5 18zm0 10.526a2.105 2.105 0 0 0-2.105 2.105v2.105a2.105 2.105 0 0 1-2.105 2.105H7.816a2.105 2.105 0 0 1-2.105-2.105V16.755L18.09 29.134a2.106 2.106 0 0 0 2.989-2.968l-12.4-12.38h11.768a2.105 2.105 0 1 0 0-4.211H7.816A6.316 6.316 0 0 0 1.5 15.892v21.052a6.316 6.316 0 0 0 6.316 6.316H37.29a6.316 6.316 0 0 0 6.316-6.316v-2.105a2.105 2.105 0 0 0-2.106-2.105z"
      transform="translate(8.5 8.74)"
    />,
  ],
});
