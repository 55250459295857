import React from 'react';
import { Box, Button, Divider, Radio, RadioGroup, VStack } from '@chakra-ui/react';

export const PreferencesPage = () => {
  return (
    <VStack alignItems="flex-start" spacing={6}>
      <Box fontWeight={600}>Machine default</Box>

      <VStack alignItems="flex-start" w="full">
        <RadioGroup defaultValue="failures" w="full">
          <Radio value="failures">
            <Box fontSize="0.875rem" fontWeight={600} py={6}>
              Display machine failure
            </Box>
          </Radio>
          <Divider />
          <Radio value="components">
            <Box fontSize="0.875rem" fontWeight={600} py={6}>
              Display machine components
            </Box>
          </Radio>
          <Divider />
        </RadioGroup>
      </VStack>

      <Box pt={6}>
        <Button variant="data-mind" size="lg" borderRadius="full" w="full">
          Save changes
        </Button>
      </Box>
    </VStack>
  );
};
