import { api } from 'src/app/api';
import { Diagnostics } from 'src/data/diagnostics';

type DiagnosticsResponse = Diagnostics;

export const diagnosticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDiagnostics: builder.query<DiagnosticsResponse, void>({
      query: () => ({ url: 'diagnostics' }),
      providesTags: () => [{ type: 'Diagnostics' }],
    }),
  }),
});

export const { useGetDiagnosticsQuery } = diagnosticsApi;
