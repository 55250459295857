import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';
import { Summary } from 'src/pages/sites/components/summary/Summary';
import SitesHeader from 'src/pages/sites/components/SitesHeader';
import SitesList from 'src/pages/sites/components/SitesList';
import { Toolbar } from 'src/components/Toolbar';
import { useGetSitesQuery } from 'src/app/services/sites';

export const SitesPages = () => {
  const { data: sites, isLoading } = useGetSitesQuery();

  return (
    <>
      <Box w="full" p="4rem" pt={0} pr="25.25rem">
        <Toolbar />
        <SitesHeader />
        {isLoading ? <Spinner /> : <SitesList sites={sites} />}
      </Box>

      <Summary />
    </>
  );
};
