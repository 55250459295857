export const data = [
  {
    name: '2021-09-01',
    value: ['2021-09-01', 4],
  },
  {
    name: '2021-10-01',
    value: ['2021-10-01', 4],
  },
  {
    name: '2021-11-01',
    value: ['2021-11-01', 2],
  },
  {
    name: '2021-12-01',
    value: ['2021-12-01', 1],
  },
  {
    name: '2022-01-01',
    value: ['2022-01-01', 4],
  },
  {
    name: '2022-02-01',
    value: ['2022-02-01', 4],
  },
  {
    name: '2022-03-01',
    value: ['2022-03-01', 2],
  },
  {
    name: '2022-04-01',
    value: ['2022-04-01', 2],
  },
  {
    name: '2022-05-01',
    value: ['2022-05-01', 1],
  },
  {
    name: '2022-06-01',
    value: ['2022-06-01', 4],
  },
  {
    name: '2022-07-01',
    value: ['2022-07-01', 4],
  },
  {
    name: '2022-08-01',
    value: ['2022-08-01', 4],
  },
  {
    name: '2022-09-01',
    value: ['2022-09-01', 4],
  },
  {
    name: '2022-10-01',
    value: ['2022-10-01', 3],
  },
  {
    name: '2022-11-01',
    value: ['2022-11-01', 3],
  },
  {
    name: '2022-12-01',
    value: ['2022-12-01', 2],
  },
  {
    name: '2023-01-01',
    value: ['2023-01-01', 4],
  },
  {
    name: '2023-02-01',
    value: ['2023-02-01', 4],
  },
  {
    name: '2023-03-01',
    value: ['2023-03-01', 4],
  },
];
