import React from 'react';
import { Box, HStack, IconButton } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { DMBackArrowIcon, DMSitesIcon } from 'src/components/Icons';
import { useSticky } from 'src/hooks/useSticky';
import { useMatch, useNavigate } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { FUSION_TRENDS_TYPE, HEALTH_STATUS } from 'src/const';
import { MachinesDropdown } from 'src/components/MachinesDropdown';
import { ExportDrawer } from 'src/components/export-drawer/ExportDrawer';
import { FusionTrendsModal } from 'src/pages/machines/modals/FusionTrendsModal';
import { SitemapModal } from 'src/pages/machines/modals/SitemapModal';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { LastRecorded } from 'src/components/LastRecorded';
import { SearchControl } from 'src/components/SearchControl';
import { LayoutToggleButtons } from 'src/components/LayoutToggleButtons';

type ToolbarProps = {
  siteName?: string;
  machineName?: string;
  isTopFixed?: boolean;
};

export const Toolbar = ({ siteName = 'Sites', machineName, isTopFixed = false }: ToolbarProps) => {
  const navigate = useNavigate();
  const [sticky] = useSticky();
  const matchSitesLocation = useMatch(locations.protected.sites.index);
  const matchMachinesLocation = useMatch(locations.protected.sites.machines);
  const matchFailuresLocation = useMatch(locations.protected.sites.failures);

  const style = {
    ...toolbarBaseStyle,
    ...(isTopFixed ? toolbarFixedStyle : sticky ? toolbarStickyStyle : null),
  };

  const goBack = () => navigate(-1);

  return (
    <HStack css={{ ...style }} w="full" justifyContent="space-between">
      <HStack spacing={4}>
        {matchSitesLocation ? null : (
          <IconButton
            variant="outline"
            aria-label="Back"
            size="sm"
            fontSize="20px"
            icon={<DMBackArrowIcon />}
            borderRadius="full"
            onClick={goBack}
          />
        )}

        <HStack>
          <DMSitesIcon fontSize="1.5rem" />
          <Box fontSize="1rem" fontWeight={500}>
            {siteName}
          </Box>
        </HStack>

        <Box>{matchFailuresLocation ? <MachinesDropdown current={machineName} /> : null}</Box>
      </HStack>

      <HStack spacing={1}>
        {matchFailuresLocation ? (
          <HStack spacing={4}>
            <HealthStatusIndicator type={HEALTH_STATUS.CRITICAL} />
            <LastRecorded date={new Date(new Date())} />
          </HStack>
        ) : null}
        {matchFailuresLocation ? (
          <FusionTrendsModal title="Equipment fusion trend" type={FUSION_TRENDS_TYPE.Machine} />
        ) : null}
        <SearchControl isTopFixed={isTopFixed} sticky={sticky} />
        {matchMachinesLocation ? <SitemapModal /> : null}
        <ExportDrawer />
        {matchMachinesLocation ? <LayoutToggleButtons /> : null}
        {matchFailuresLocation ? <ViewSwitcher /> : null}
      </HStack>
    </HStack>
  );
};

const toolbarBaseStyle = css`
  width: 100%;
  height: 4rem;
  background-color: transparent;
`;

const toolbarFixedStyle = css`
  position: fixed;
  top: 3.5rem;
  left: 0;
  height: 4rem;
  z-index: 2;
  background-color: #fff;
  padding-left: 7.5rem;
  padding-right: 4rem;
  border-bottom: 1px solid rgba(1, 10, 9, 0.1);
`;

const toolbarStickyStyle = css`
  position: fixed;
  top: 3.5rem;
  left: 0;
  padding-right: 25.25rem;
  padding-left: 7.5rem;
  z-index: 2;
  width: 100%;
  height: 4rem;
  background-color: #fff;
  border-bottom: 1px solid rgba(1, 10, 9, 0.1);
`;
