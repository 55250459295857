export type SummaryDataType = {
  id: string;
  label: string;
  value: number;
  color: string;
};

export const summary: SummaryDataType[] = [
  {
    id: 'critical',
    label: 'critical',
    value: 3,
    color: '#de6f62',
  },
  {
    id: 'alarm',
    label: 'alarm',
    value: 3,
    color: '#ff891b',
  },
  {
    id: 'monitor',
    label: 'monitor',
    value: 5,
    color: '#f1bf42',
  },
  {
    id: 'healthy',
    label: 'healthy',
    value: 25,
    color: '#58a65c',
  },
  {
    id: 'notAvailable',
    label: 'N/A',
    value: 1,
    color: '#021d3d',
  },
];
