import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { DMArrowRightIcon, DMSensorsIcon } from 'src/components/Icons';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { HEALTH_STATUS } from 'src/const';
import { SensorHealthStatus } from 'src/data/sensors';

interface MachineSensorsModalProps {
  sensors?: SensorHealthStatus[];
}

export const MachineSensorsModal = ({ sensors }: MachineSensorsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onMachineSensorsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onOpen();
  };

  const getSensorsOverallStatus = () => {
    if (sensors) {
      for (const sensor of sensors) {
        if (sensor.health_status === HEALTH_STATUS.CRITICAL) {
          return true;
        }
      }
    }

    return false;
  };

  const isSensorsOverallStatusCritical = getSensorsOverallStatus();

  return (
    <>
      <Button variant="unstyled" display="flex" onClick={onMachineSensorsClick} size="sm">
        <DMSensorsIcon
          fontSize="1.5rem"
          mr={2}
          color={isSensorsOverallStatusCritical ? 'health.critical' : 'health.healthy'}
        />
        <Box fontSize="0.75rem">8 Sensors</Box>
        <DMArrowRightIcon fontSize="1rem" />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <ModalHeader fontWeight={400} fontSize="1rem">
            Tertiary Crusher 2 sensors
          </ModalHeader>
          <ModalCloseButton top={3} right={4} />
          <ModalBody pb={6}>
            <Table>
              <Thead>
                <Tr
                  css={{
                    borderRadius: '1rem',
                    backgroundColor: 'rgba(1, 10, 9, 0.12)',
                    th: {
                      padding: '0.5rem 1rem 0.5rem 1rem',
                      fontSize: '0.625rem',
                    },
                  }}
                >
                  <Th>Sensor name</Th>
                  <Th>Health status</Th>
                  <Th>Last report</Th>
                  <Th>Reason for status</Th>
                  <Th>What to do</Th>
                  <Th>Type</Th>
                  <Th>Location</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="0.875rem">
                {sensors &&
                  sensors.map((item, index) => (
                    <Tr
                      key={index}
                      css={{
                        td: {
                          padding: '1.5rem 1rem 1.5rem 1rem',
                        },
                      }}
                    >
                      <Td>Sensor name</Td>
                      <Td>
                        <HealthStatusIndicator type={item.health_status} />
                      </Td>
                      <Td>09.03.23 10:46</Td>
                      <Td>Placeholder text</Td>
                      <Td>Lorem ipsum dolor</Td>
                      <Td>Vibration</Td>
                      <Td>Component 7, Bearing</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
